import { tr } from 'pmt-modules/i18n'
import StatusChip from 'pmt-ui/StatusChip'

export const FeedbackAnswerStatus = {
  CREATED: 'CREATED',
  EMAIL_OPENED: 'EMAIL_OPENED',
  FORM_OPENED: 'FORM_OPENED',
  ANSWERED: 'ANSWERED',
}

export const FeedbackObjectType = {
  ORDER: 'ORDER',
}

export const getFeedbackAnswerStatusLabel = status => {
  switch (status) {
    case FeedbackAnswerStatus.CREATED:
      return tr('global.feedback.status.CREATED')
    case FeedbackAnswerStatus.EMAIL_OPENED:
      return tr('global.feedback.status.EMAIL_OPENED')
    case FeedbackAnswerStatus.ANSWERED:
      return tr('global.feedback.status.ANSWERED')
    case FeedbackAnswerStatus.FORM_OPENED:
      return tr('global.feedback.status.FORM_OPENED')
    default:
      return status
  }
}

export const getFeedbackAnswerStatusType = status => {
  switch (status) {
    case FeedbackAnswerStatus.CREATED:
      return StatusChip.Status.DEFAULT
    case FeedbackAnswerStatus.EMAIL_OPENED:
      return StatusChip.Status.INFO
    case FeedbackAnswerStatus.ANSWERED:
      return StatusChip.Status.VALID
    case FeedbackAnswerStatus.FORM_OPENED:
      return StatusChip.Status.WARNING
    default:
      return status
  }
}
