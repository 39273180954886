/**
 * Authentication mode
 * Caution: this is a bitfield
 */
export const AuthMode = {
  NORMAL: 0,
  LIGHT: 1,
  INCOGNITO_ONLY: 2,
}

/**
 * Delay within token should force refresh before doing anything else
 */
export const ACCESS_TOKEN_DELAY_REFRESH = 1 * 60 * 1000 // 1 minutes

/**
 * Delay within it is acceptable to refresh token in background
 */
export const ACCESS_TOKEN_DELAY_REFRESH_SOON = 3 * 60 * 1000 // 3 minutes

/**
 * Refresh token lifetime. Used to expire cookie.
 */
export const REFRESH_TOKEN_LIFETIME = 13 * 24 * 60 * 60 * 1000 // 13 days

export const INCOGNITO_TOKEN_LIFETIME = 365 * 24 * 60 * 60 * 1000 // 365 days

export const StorageItem = {
  ACCESS_TOKEN: 'STORAGE::ACCESS_TOKEN',
}
