import { SdkRoutingEnum, Route } from 'pmt-modules/routing'

const GlobalRoutingEnum = {
  ...SdkRoutingEnum,

  //
  // Feedback
  //
  GLOBAL_FEEDBACK_ANSWER: new Route('GLOBAL::FEEDBACK_ANSWER', '/feedback/:feedbackAnswerToken'),

  GLOBAL_FEEDBACK_CONFIRM: new Route(
    'GLOBAL::FEEDBACK_CONFIRM',
    '/feedback/:feedbackAnswerToken/confirm'
  ),
}

export default GlobalRoutingEnum
