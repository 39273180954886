/**
 * http://stackoverflow.com/questions/1436438/how-do-you-set-clear-and-toggle-a-single-bit-in-javascript
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Bitwise_Operators
 */

export const isBitSet = (mask, flag) => {
  return (flag & mask) !== 0
}

export const setBit = (mask, flag) => {
  return flag | mask
}

export const toggleBit = (mask, flag) => {
  return flag ^ mask
}

export const removeBit = (mask, flag) => {
  return flag & ~mask
}
