import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import { getRoutes, getHistory } from 'pmt-modules/routing'

import ErrorBoundary from 'pmt-ui/ErrorBoundary'

import Content from './Content'
import configureRoutes from './utils/configureRoutes'

const App = ({ store }) => {
  /* place ConnectedRouter under Provider */
  const router = (
    <ConnectedRouter history={getHistory()}>
      {configureRoutes({
        routes: getRoutes(),
      })}
    </ConnectedRouter>
  )

  const persistor = persistStore(store)

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Content>{router}</Content>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired,
}

export default App
