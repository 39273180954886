//
// utility to create a middleware function. Avoid boilerplate.
//
// Example:
//
// ```
// const middleware = createMiddleware(Action.REQUEST, ({ getState, dispatch, next, action }) => {
//
// })
// ```
//
//
import findIndex from 'lodash/findIndex'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import invariant from 'invariant'
import { __DEV__ } from '../environment'

const STOP_PROPAGATION = -1

/**
 *
 * @param  {any} actionsToHandleParam can either be:
 *                                    - function: (action) -> bool
 *                                    - array of `type` constants
 *                                    - `type` constant
 * @param  {func} func                the body of the middleware
 */
const createMiddleware = (actionsToHandleParam, func) => ({
  getState,
  dispatch,
}) => next => action => {
  if (__DEV__) {
    invariant(isFunction(func), 'middleware must be a function')
    invariant(!isNil(actionsToHandleParam), 'actionsToHandleParam nil for ' + func)
  }

  let res = null

  //
  if (typeof action === 'object' && action.hasOwnProperty('type')) {
    // handle actionsToHandleParam as array of actions and as a single action
    let actionsToHandle = actionsToHandleParam
    if (isFunction(actionsToHandleParam) && actionsToHandleParam.TYPE) {
      // the user gives an action as actionsToHandleParam, we need to use the type
      actionsToHandle = actionsToHandleParam.TYPE
    }

    let toHandle = false

    if (isFunction(actionsToHandle)) {
      toHandle = actionsToHandle(action)
    } else {
      // handle actionsToHandleParam as array of actions and as a single action: we pass it to an
      // array
      if (!isArray(actionsToHandle)) {
        actionsToHandle = [actionsToHandle]
      }

      toHandle =
        findIndex(actionsToHandle, actionToHandle => {
          return action.type === actionToHandle
        }) !== -1
    }

    if (toHandle) {
      try {
        res = func({
          getState,
          dispatch,
          next,
          action,
        })
      } catch (e) {
        console.error(`Error on middleware`, { action })
        console.error(e)
      }

      // action should stop if we get a null result
      if (res !== STOP_PROPAGATION) {
        return isNil(res) // in case we forgot the final `return next(action)` on the middleware
          ? next(action)
          : res
      }
    }
  }

  //
  if (isNull(res) || res !== STOP_PROPAGATION) {
    return next(action)
  }
}

createMiddleware.STOP_PROPAGATION = STOP_PROPAGATION

export default createMiddleware
