import Loadable from 'react-loadable'

export const createLoadable = (loader, loading) => {
  return Loadable({
    loader: () => {
      if (process.env.__DEV__) {
        console.info(`[Loadable] loading`)
      }

      return loader()
    },
    loading,
  })
}

export default Loadable
