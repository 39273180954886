import { getForCurrentLocale } from 'pmt-modules/i18n/'

import { formatPercent, formatPriceWithCurrency } from 'pmt-utils/currency'
import { createFormatter, createListFormatter } from 'pmt-utils/format'

import { FeeSettingStatus, FeeType } from '../constants'

//  "fees": [
//     {
//       "type": "DELIVERY", // DELIVERY / SERVICE
//       "status": "ENABLED",
//       "name": [
//         {
//           "locale": "fr-FR",
//           "text": "Frais de livraison"
//         },
//         {
//           "locale": "en-US",
//           "text": "Delivery"
//         }
//       ],
//       "publicDescription": [
//         {
//           "locale": "fr-FR",
//           "text": "Explication du pourquoi de la fee"
//         },
//         {
//           "locale": "en-US",
//           "text": "delivery fee explanation"
//         }
//       ],
//       "privateComment": "comment for the pro only: why we do this",
//       "settings": {
//         "amountIncludingTax": 4.42,
//         "taxPercent": 20,
//         "minimumPriceForFree": 20,
//         "filters": {
//           "modes": [
//             2
//           ]
//         },
//         "posProductId": null
//       }
//     }
//   ]

const formatOrderSettingsFee = feeSetting => {
  feeSetting.isStatusEnabled = feeSetting.status === FeeSettingStatus.ENABLED

  feeSetting.isTypeDelivery = feeSetting.type === FeeType.DELIVERY
  feeSetting.isTypeService = feeSetting.type === FeeType.SERVICE

  feeSetting.nameFormatted = getForCurrentLocale(feeSetting.name)
  feeSetting.publicDescriptionFormatted = getForCurrentLocale(feeSetting.publicDescription)

  feeSetting.settings.amountIncludingTaxFormatted = formatPriceWithCurrency(
    feeSetting.settings.amountIncludingTax
  )
  feeSetting.settings.minimumPriceForFreeFormatted = formatPriceWithCurrency(
    feeSetting.settings.minimumPriceForFree
  )
  feeSetting.settings.taxPercentFormatted = formatPercent(feeSetting.settings.taxPercent)

  feeSetting.settings.taxAmountFormatted = formatPercent(feeSetting.settings.taxAmount)

  // minimum to 0 === no minimum
  feeSetting.settings.hasMinimumPriceForFree = feeSetting.settings.minimumPriceForFree > 0

  return feeSetting
}

export const formatOrderSettingsFees = createFormatter(createListFormatter(formatOrderSettingsFee))
