import { getApiUrl, getApiConsumer } from 'pmt-modules/environment'

const getApiManagerOptions = state => ({
  apiUrl: getApiUrl(),

  headersMiddleware: () => {
    const headers = {
      'api-consumer': getApiConsumer(),
      // 'restaurant-id': '16000106'
    }

    return headers
  },
})

export default getApiManagerOptions
