const ApiEndpoints = {
  RESTAURANTS_GROUPS: '/restaurants-groups',

  RESTAURANTS_GROUP: '/restaurants-groups/:restaurantsGroupId',
  RESTAURANTS_GROUP_RESTAURANTS: '/restaurants-groups/restaurants',
  RESTAURANTS_GROUP_HIERARCHY: '/restaurants-groups/hierarchy',

  API_CONSUMERS: '/restaurants-groups/:restaurantsGroupId/api-consumers',
  API_CONSUMERS_RESTAURANT: '/api-consumers/restaurants/:restaurantId',
  API_CONSUMER: '/api-consumers/:apiConsumerId',
  API_KEY: '/api-consumers/:apiConsumerId/apikey',
  API_CONSUMER_VALIDATION: '/api-consumers/:apiConsumerId/validate',
  CREATE_API_CONSUMER: '/api-consumers',

  RESTAURANTS: '/restaurants',
  RESTAURANT_LIST: '/restaurants',
  RESTAURANT: '/restaurants/:restaurantId',

  RESTAURANT_CHECKUP: '/checkup/restaurants',

  RESTAURANT_DOCUMENTS_KYC: '/restaurants/:restaurantId/docs/kyc',

  RESTAURANTS_GROUP_SEARCH_USERS: '/restaurants-groups/:rGroupId/users/search',

  KIOSK_SETTINGS: '/restaurants/:restaurantId/kiosk-settings',

  // TODO: add 's'
  GET_CHECK_TABLE_NUMBER: '/restaurant/:restaurantId/table/:tableNumber/check',

  GET_CHECK_POS_ID: '/restaurant/:restaurantId/pos-check-id/:posCheckId/check',

  GET_CHECK: '/restaurants/:restaurantId/checks/:checkId',

  PUT_MERGE_CHECKS: '/restaurants/:restaurantId/checks/merge',

  GET_CHECK_CODE: '/restaurant/:restaurantId/code/:code/check',

  RESTAURANT_USER_ACCOUNTS: '/restaurants/:restaurantId/user-accounts',

  OAUTH_TOKEN: '/oauth/token',

  OAUTH_AUTHORIZE_APP: '/oauth/authorize',

  REGISTER: '/users',

  ME_CARD_REGISTRATION: '/users/me/cards-registration',
  ME_CARD_REGISTRATION_TYPE: '/users/me/cards-registration/v2',

  ME_CARD: '/users/me/cards',
  ME_CARD_V2: '/users/me/cards/v2',

  USER_ME: '/users/me',

  USER_CARDS: '/users/:userId/cards',
  USER_CARDS_DELETE: '/users/:userId/cards/:cardId',

  USER_ACCOUNTS: '/users/:userId/accounts',
  USER_ACCOUNT: '/users/:userId/accounts/:userAccountId',
  USER_ACCOUNT_TRANSACTIONS: '/users/:userId/accounts/:userAccountId/transactions',

  USER_ADDRESSES: '/users/:userId/addresses',
  USER_ADDRESS: '/users/:userId/addresses/:addressId',
  USER_FORGOT_PASSWORD: '/users/password-recovery/recover',

  RGROUP_USERS: '/restaurants-groups/:rGroupId/users',
  RGROUP_USER: '/restaurants-groups/:rGroupId/users/:userId',

  USERS: '/users',
  USER: '/users/:userId',

  DEPOSITS: '/deposits',
  DEPOSIT: '/deposits/:depositId',

  GET_PAYMENT: '/payments/:paymentId',
  POST_PAYMENT_EMAIL: '/payments/:paymentId/email',
  GET_PAYMENT_PDF_LINK: '/payments/:paymentId/pdf/link',
  POST_PAYMENT: '/restaurant/:restaurantId/check/:checkId/payment',
  GET_ASYNC_PAYMENT: '/payments/async/:paymentId',

  HISTORY_USER_ORDERS: '/orders',
  HISTORY_USER_BOOKINGS: '/bookings',
  HISTORY_USER_PAYMENTS: '/payments',

  PAYMENTS_HISTORY: '/payments',

  ORDER_PREVIEW: '/orders-previews',

  ORDERS: '/orders',
  ORDERS_ON_GOING: '/orders/on-goings',
  ORDERS_HISTORY: '/orders',

  ORDER: '/orders/:orderId',

  PRO_ME: '/pro/accounts/me',

  PRO_ACCOUNTS: '/pro/accounts',

  PRO_ACCOUNT: '/pro/accounts/:proAccountId',

  PRO_DOMAINS: '/pro/domains',

  PRO_DOMAIN: '/pro/domains/:domainId',

  PRO_ME_DOMAINS: '/pro/accounts/me/domains',

  PRO_ME_DOMAIN: '/pro/domains/:domainId',

  PRO_ROLES: '/pro/roles',
  PRO_ROLE: '/pro/roles/:roleId',

  PRO_FORGOT_PASSWORD: '/pro/password-recovery/recover',

  IMPORT_USER: '/restaurants-groups/:rGroupId/import/user-accounts',

  /**
   * Statistics
   */
  STATISTICS_ORDER: '/restaurants-groups/:restaurantsGroupId/stats/order/general-stats',
  STATISTICS_DEPOSIT: '/restaurants-groups/:restaurantsGroupId/stats/deposit/general-stats',
  STATISTICS_BALANCE_FOR_DAY: '/restaurants-groups/:restaurantsGroupId/stats/balance/for-day',

  EXPORT_DEPOSITS: '/restaurants-groups/:restaurantsGroupId/export/deposits',
  EXPORT_SUBSIDY_BILL: '/restaurants-groups/:restaurantsGroupId/export/subsidy-bill',
  EXPORT_SALES: '/restaurants-groups/:restaurantsGroupId/export/sales',

  EXPORT_RESTAURANTS_GROUP_DATA: '/restaurants-groups/:restaurantsGroupId/export',

  /**
   * CATALOG
   */
  MENU: '/restaurants/:restaurantId/menu',

  /**
   * CATALOG
   */
  CATALOG: '/restaurants/:restaurantId/catalog/v2',

  //
  //
  //

  ENTITY_HISTORY_LIST: '/history',
  ENTITY_HISTORY: '/history/:entityHistoryId',
  ENTITY_HISTORY_FOR_SESSION: '/history/sessions/:sessionId',

  //
  // Feedback
  //
  FEEDBACK_CAMPAIGNS: '/feedbacks/campaigns',
  FEEDBACK_CAMPAIGN: '/feedbacks/campaigns/:feedbackCampaignId',
  FEEDBACK_CAMPAIGN_EXPORT: '/feedbacks/campaigns/:feedbackCampaignId/export',
  FEEDBACK_ANSWERS: '/feedbacks/campaigns/:feedbackCampaignId/answers',
  FEEDBACK_ANSWER_TOKEN: '/feedbacks/answers/token/:feedbackAnswerToken',
  FEEDBACK_RESUME_FOR_OBJECT: '/feedbacks/answers/:objectType/:objectId',
  FEEDBACK_ANSWERS_USER: '/feedbacks/answers/users/:userId',

  //
  // Scheduled Actions
  //
  SCHEDULED_ACTIONS: '/scheduled-actions',
  SCHEDULED_ACTION: '/scheduled-actions/:scheduledActionId',
  SCHEDULED_ACTION_RUN: '/scheduled-actions/run/:scheduledActionId',

  //
  // Template
  //
  CUSTOMS_TEXTS: '/customs/texts-containers',
  CUSTOMS_TEXTS_CONTAINER: '/customs/texts-containers/:containerId',
  TEMPLATE_EMAIL_DATA: '/templates/preview/email/data',

  //
  // Audit
  //
  EVENTS_SEARCH: '/events/search',

  EVENTS_SESSION: '/events/sessions/:sessionId',

  AUDIT_LOGS_SEARCH: '/audit/logs/search',

  //
  //
  //
  FIREBASE_AUTH: '/firebase/auth',

  //
  //
  //
  PRO_NOTIFICATION_IDENTITIES: '/pro-notifications/identities',
  PRO_NOTIFICATIONS: '/pro-notifications',
  PRO_NOTIFICATION: '/pro-notifications/:proNotificationSettingId',

  //
  // Media
  //
  MEDIA: '/restaurants-groups/:rGroupId/media',
  MEDIA_DETAIL: '/restaurants-groups/:rGroupId/media/:mediaId',
  MEDIA_OPEN: '/restaurants-groups/:rGroupId/media/:mediaId/open',

  //
  //
  //
  API_ASYNC_SERVICE_DATA: '/service-async/:asyncServiceId',

  //
  // PIM
  //

  // -- referential
  PIM_REFERENTIALS: '/pim/referential',
  PIM_REFERENTIAL: '/pim/referential/:referentialId',

  PIM_CLUSTERS: '/pim/referential/:referentialId/pricing-clusters-settings',
  PIM_CLUSTER: '/pim/referential/:referentialId/pricing-clusters-settings/:clusterId',

  PIM_DASHBOARD_STATS: '/pim/referential/:referentialId/dashboard/stats',

  PIM_CHANNELS: '/pim/referential/:referentialId/channels',
  PIM_CHANNEL: '/pim/channels/:channelId',
  // -- channel generation
  PIM_CHANNEL_GENERATIONS: '/pim/channels/:channelId/generations',
  PIM_CHANNEL_GENERATIONS_ON_GOING: '/pim/channels/:channelId/generations/on-going',
  PIM_CHANNEL_LAST_GENERATION: '/pim/channels/:channelId/generations/last',

  PIM_CHANNEL_GENERATION: '/pim/channels-generations/:channelGenerationId',
  PIM_CHANNEL_GENERATION_RESTAURANT:
    '/pim/channels-generations/:channelGenerationId/restaurants/:restaurantId',
  PIM_CHANNEL_GENERATION_RESTAURANT_LOGS:
    '/pim/channels-generations/:channelGenerationId/restaurants/:restaurantId/logs',
  PIM_CHANNEL_GENERATION_RESTAURANT_DATA:
    '/pim/channels-generations/:channelGenerationId/restaurants/:restaurantId/data',

  // -- category containers
  PIM_CATEGORY_CONTAINERS: '/pim/referential/:referentialId/catalog/category-containers',
  PIM_CATEGORY_CONTAINER:
    '/pim/referential/:referentialId/catalog/category-containers/:categoryContainerId',

  PIM_CATEGORIES:
    '/pim/referential/:referentialId/catalog/category-containers/:categoryContainerId/categories',
  PIM_CATEGORY: '/pim/referential/:referentialId/catalog/categories/:categoryId',
  PIM_CATEGORIES_COMMANDS:
    '/pim/referential/:referentialId/catalog/category-containers/:categoryContainerId/commands',
  PIM_CATEGORY_ITEMS: '/pim/referential/:referentialId/catalog/categories/:categoryId/items',

  // -- products
  PIM_PRODUCTS: '/pim/referential/:referentialId/catalog/products',
  PIM_PRODUCT: '/pim/referential/:referentialId/catalog/products/:productId',
  PIM_PRODUCT_ACTIVATIONS:
    '/pim/referential/:referentialId/catalog/products/:productId/item-activations',
  PIM_PRODUCT_PRICES: '/pim/referential/:referentialId/catalog/products/:productId/pricing',

  PIM_PRODUCT_MENUS: '/pim/referential/:referentialId/catalog/products/:productId/menus',

  PIM_PRODUCT_OPTIONS: '/pim/referential/:referentialId/catalog/products/:productId/options',
  PIM_PRODUCT_OPTION:
    '/pim/referential/:referentialId/catalog/products/:productId/options/:optionId',

  // -- menus
  PIM_MENUS: '/pim/referential/:referentialId/catalog/menus',
  PIM_MENU: '/pim/referential/:referentialId/catalog/menus/:menuId',
  PIM_MENU_ACTIVATIONS: '/pim/referential/:referentialId/catalog/menus/:menuId/item-activations',
  PIM_MENU_PRICES: '/pim/referential/:referentialId/catalog/menus/:menuId/pricing',
  PIM_MENU_MENU_PARTS: '/pim/referential/:referentialId/catalog/menus/:menuId/parts',

  PIM_MENU_PARTS: '/pim/referential/:referentialId/catalog/menus/:menuId/parts',
  PIM_MENU_PART: '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId',
  PIM_MENU_PART_ACTIVATIONS:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/item-activations',
  PIM_MENU_PART_PRODUCT:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/products/:productId',
  PIM_MENU_PART_PRODUCT_PRICES:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/products/:productId/pricing',

  PIM_MENU_PART_CATEGORIES:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/categories',
  PIM_MENU_PART_CATEGORY:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/categories/:menuPartCategoryId',
  PIM_MENU_PART_CATEGORIES_COMMAND:
    '/pim/referential/:referentialId/catalog/menus-parts/:menuPartId/categories/commands/set-categories-tree',

  // -- items, common for product and menu
  PIM_ITEM_CATEGORIES: '/pim/referential/:referentialId/catalog/:itemType/:itemId/categories',
  PIM_ITEM_SUGGESTIONS: '/pim/referential/:referentialId/catalog/:itemType/:itemId/suggestions',
  PIM_ITEM_SUGGESTED: '/pim/referential/:referentialId/catalog/:itemType/:itemId/suggested',

  // -- options
  PIM_OPTIONS: '/pim/referential/:referentialId/catalog/options',
  PIM_OPTION: '/pim/referential/:referentialId/catalog/options/:optionId',
  PIM_OPTION_ACTIVATIONS:
    '/pim/referential/:referentialId/catalog/options/:optionId/item-activations',

  PIM_OPTION_PRODUCTS: '/pim/referential/:referentialId/catalog/options/:optionId/products',
  PIM_OPTION_VALUES: '/pim/referential/:referentialId/catalog/options/:optionId/values',
  PIM_OPTION_VALUE: '/pim/referential/:referentialId/catalog/options-values/:optionValueId',
  PIM_OPTION_VALUE_ACTIVATIONS:
    '/pim/referential/:referentialId/catalog/options-values/:optionValueId/item-activations',
  PIM_OPTION_VALUE_PRICES:
    '/pim/referential/:referentialId/catalog/options-values/:optionValueId/pricing',

  // -- search
  PIM_SEARCH_PRODUCTS: '/pim/referential/:referentialId/catalog/search/products',
  PIM_SEARCH_MENUS: '/pim/referential/:referentialId/catalog/search/menus',

  // -- suggestions
  PIM_SUGGESTIONS: '/pim/referential/:referentialId/catalog/suggestions',
  PIM_SUGGESTION: '/pim/referential/:referentialId/catalog/suggestions/:suggestionId',

  // -- upsells
  PIM_UPSELLS: '/pim/referential/:referentialId/catalog/upselling',
  PIM_UPSELL: '/pim/referential/:referentialId/catalog/upselling/:upsellId',

  // -- views
  PIM_VIEWS: '/pim/referential/:referentialId/views',
  PIM_VIEW: '/pim/views/:viewId',
  PIM_VIEW_GENERATIONS: '/pim/views/:viewId/generations',
  PIM_VIEW_GENERATIONS_ON_GOING: '/pim/views/:viewId/generations/on-going',

  // -- view generations
  PIM_VIEW_GENERATION: '/pim/views-generations/:viewGenerationId',
  PIM_VIEW_GENERATION_CATALOG: '/pim/views-generations/:viewGenerationId/catalog',
  PIM_VIEW_GENERATION_LOGS: '/pim/views-generations/:viewGenerationId/logs',

  //
  // Payouts
  //
  PAYOUT: '/payouts/:payoutId',
  PAYOUTS_LIST: '/payout/:rGroupId/list',
  PAYOUTS_ERROR_LIST: '/payout/:rGroupId/list-errors',
}

export default ApiEndpoints
