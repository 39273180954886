import { isBitSet } from 'pmt-utils/bit'

import { Feature } from '../constants'

export const formatFeatures = (object) => {
  object.hasFeaturePayment = isBitSet(object.features, Feature.PAYMENT)
  object.hasFeatureOrder = isBitSet(object.features, Feature.ORDER)
  object.hasFeatureBooking = isBitSet(object.features, Feature.BOOKING)
  object.hasFeatureNews = isBitSet(object.features, Feature.NEWS)
  object.hasFeatureReview = isBitSet(object.features, Feature.REVIEW)

  return object
}
