import React from 'react'
import PropTypes from 'prop-types'

import isFunction from 'lodash/isFunction'
import Logger from 'pmt-utils/logger'

import ErrorOccurredView from './components/ErrorOccurredView'

/**
 * catch JavaScript errors anywhere in their child component tree, log those errors, and display a
 * fallback UI instead of the component tree that crashed. Error boundaries catch errors during
 * rendering, in lifecycle methods, and in constructors of the whole tree below them.
 *
 *
 * See https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
 *
 */
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
    info: null,
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error,
      info,
    })

    console.error(error)

    // You can also log the error to an error reporting service
    Logger.componentDidCatch(error, info)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      const errorView = this.props.errorView
      if (errorView) {
        return isFunction(errorView) ? errorView() : errorView
      }

      return (
        <ErrorOccurredView
          error={this.state.error}
          info={this.state.info}
          sentryId={Logger.lastEventId()}
        />
      )
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  errorView: null,
}

ErrorBoundary.propTypes = {
  /**
   * View to display when there is an error.
   * By default we display the ErrorOccurredView that details the error.
   */
  errorView: PropTypes.node,
}

export default ErrorBoundary
