/**
 * Default: null
 *
 * Defines different display possibilities for the Body
 */
const ViewType = {
  // Must not be on 'multiple' mode.
  // Will display a radio button next to the selected value
  RADIO: 'radio',
}

export default ViewType
