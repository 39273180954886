import React from 'react'
import PropTypes from 'prop-types'

import EventManager from 'pmt-modules/event/EventManager'

/**
 * @specs N/A
 *
 * A HOC that send an event
 *
 */
class SendEventContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (props.send) {
      this.sendEvent(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.send && !this.props.send) {
      this.sendEvent(nextProps)
    }
  }

  sendEvent = props => {
    EventManager.dispatch(props.event, props.eventProps ? props.eventProps() : {})
  }

  render() {
    return React.cloneElement(this.props.children)
  }
}

SendEventContainer.defaultProps = {
  send: true,
}

SendEventContainer.propTypes = {
  children: PropTypes.object.isRequired,

  event: PropTypes.string.isRequired,
  eventProps: PropTypes.func,

  send: PropTypes.bool,
}

export default SendEventContainer
