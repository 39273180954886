import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import moment from 'moment'

import { getStatistics, updateStatisticsOptions } from '../actions'

import {
  makeGetStatisticsItems,
  makeIsFetchingStatistics,
  makeGetStatisticsError,
  makeGetStatisticsOptions,
  makeGetStatisticsConfig,
} from '../selectors'

/**
 * @specs N/A
 *
 * A HOC that give a restaurants group statistics
 *
 * Requirements:
 * - apiCallProps with rGroupId
 * - type defined in ../StatisticsGroupType
 *
 * see `withRestaurantNews`
 */
class StatisticsContainer extends React.Component {
  componentWillMount() {
    this.loadStatistics(this.props.options)
  }

  componentWillReceiveProps(nextProps) {
    /**
     * check if options have changed
     * this is used for imbricated calls to this container
     * this will not impact first level container
     * moment object have identifiers which invalidates isEqual
     * so we use isSame when moment, isEqual otherwise to check if our options have changed
     */
    const res = Object.keys(nextProps.options).filter(option => {
      // we don't want to reload on the API for every option changed.
      // keysThatRunApiReload contains the options keys that must produce a reload
      if (nextProps.config.keysThatRunApiReload.indexOf(option) === -1) {
        return false
      }

      // this option is listed as an option that re-run the api calls once changed, verify it has
      // changed

      if (moment.isMoment(nextProps.options[option])) {
        return !nextProps.options[option].isSame(this.props.options[option])
      }
      return !isEqual(nextProps.options[option], this.props.options[option])
    })

    if (res.length > 0 || !this.props.options) {
      this.loadStatistics(nextProps.options)
    }
  }

  loadStatistics(options = null) {
    // group container didn't handle options yet
    if (!this.props.options) {
      return
    }
    this.props.getStatistics(this.props.groupType, this.props.type, {
      options: options || this.props.options,
    })
  }

  handleChangeOptions = (options, reload = false) => {
    !reload
      ? this.props.updateStatisticsOptions(this.props.groupType, options)
      : this.loadStatistics(options)
  }

  render() {
    const {
      className,
      hideTimeRange,
      isFetchingStatistics,
      statistics,
      errorStatistics,
      children,
      options,
      ...otherProps
    } = this.props

    return (
      !isNull(options) &&
      children({
        isFetchingStatistics,
        statistics,
        errorStatistics,
        options,
        onChangeOptions: this.handleChangeOptions,
        ...otherProps,
      })
    )
  }
}

StatisticsContainer.defaultProps = {}

StatisticsContainer.propTypes = {
  children: PropTypes.func.isRequired,

  groupType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => {
  const getStatisticsItems = makeGetStatisticsItems()
  const getIsFetching = makeIsFetchingStatistics()
  const getError = makeGetStatisticsError()
  const getOptions = makeGetStatisticsOptions()
  const getConfig = makeGetStatisticsConfig()

  return {
    // TODO: rename statisticsItems
    statistics: getStatisticsItems(state, props),
    isFetchingStatistics: getIsFetching(state, props),
    errorStatistics: getError(state, props),
    options: getOptions(state, props),
    config: getConfig(state, props),
  }
}

export default connect(mapStateToProps, {
  getStatistics,
  updateStatisticsOptions,
})(StatisticsContainer)
