import React from 'react'

import isString from 'lodash/isString'
import clsx from 'clsx'
import { withStyles } from 'pmt-ui/styles'

import { isProd } from 'pmt-modules/environment'

import Typography from 'pmt-ui/Typography'
import Grid from 'pmt-ui/Grid'
import Divider from 'pmt-ui/Divider'

const styles = theme => {
  // sometimes the app crash and the theme.spacing not longer exists (mostly because it crashed
  // before we creates the theme)
  const spacing = theme.spacing ? theme.spacing : unit => 8 * unit
  return {
    root: {
      background: '#fafafa',
      height: '100%',
      padding: spacing(3),
      overflow: 'scroll',
    },
    title: {
      paddingBottom: spacing(3),
    },
    infoBlock: {
      maxWidth: 400,
      margin: '0 auto',
      textAlign: 'center',
    },
    pre: {
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
    sentryId: {
      backgroundColor: '#e74c3c',
      fontWeight: 800,
      color: 'white',
      fontSize: 16,
      padding: spacing(1),
      margin: 10,
    },
    detailBlock: {
      marginTop: spacing(3),
    },
    detailTitle: {
      paddingTop: spacing(2),
      paddingBottom: spacing(3),
      cursor: 'pointer',
    },
    hidden: {
      display: 'none',
    },
  }
}

class ErrorOccurredView extends React.Component {
  state = {
    displayDetail: !isProd(),
  }

  displayData(data) {
    if (!isString(data)) {
      try {
        return JSON.stringify(data, null, 4)
      } catch (e) {}
    }

    return String(data)
  }

  render() {
    const { error, info, sentryId, classes } = this.props

    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.title}>
          Une erreur est survenue
        </Typography>

        <div className={classes.infoBlock}>
          {sentryId && (
            <div>
              <Typography variant="body2">
                Nos équipes on été prévenues du problèmes.
                <br />
                Veuillez trouver ci-dessous l'identifiant attaché à l'erreur :<br />
              </Typography>

              <div className={classes.sentryId}>{sentryId}</div>
            </div>
          )}
        </div>

        <div className={classes.detailBlock}>
          <Divider />

          <div
            className={classes.detailTitle}
            onClick={() => this.setState({ displayDetail: !this.state.displayDetail })}
          >
            <Typography variant="body2">Détail</Typography>
          </div>

          <div
            className={clsx({
              [classes.hidden]: !this.state.displayDetail,
            })}
          >
            <Grid spacing={2} container>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2" component="pre" className={classes.pre}>
                  {this.displayData(error.message)}
                  {this.displayData(error.stack)}
                </Typography>
              </Grid>

              {/* INFO */}

              <Grid item xs={12} sm={6}>
                <Typography variant="body2" component="pre" className={classes.pre}>
                  {this.displayData(info.componentStack)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ErrorOccurredView)
