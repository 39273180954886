//
// from https://github.com/Matt-Esch/string-template/blob/master/index.js
//

// // Format using an object hash with keys matching [0-9a-zA-Z]+
//
// greeting = format("Hello {name}, you have {count} unread messages", {
//     name: "Robert",
//     count: 12
// })
// // greeting -> "Hello Robert, you have 12 unread messages"
//
//
// // Format using a number indexed array
//
// greeting = format("Hello {0}, you have {1} unread messages", ["Robert", 12])
// // greeting -> "Hello Robert, you have 12 unread messages"
//
//
// // Format using optional arguments
//
// greeting = format("Hello {0}, you have {1} unread messages",
//      "Robert",
//      12)
// // greeting -> "Hello Robert, you have 12 unread messages"
//
//
// // Escape {} pairs by using double {{}}
//
// let text = format("{{0}}")
// // text -> "{0}"

const nargs = /\{([0-9a-zA-Z_]+)\}/g

function template(string) {
  let args

  if (arguments.length === 2 && typeof arguments[1] === 'object') {
    args = arguments[1]
  } else {
    args = new Array(arguments.length - 1)
    for (let i = 1; i < arguments.length; ++i) {
      args[i - 1] = arguments[i]
    }
  }

  if (!args || !args.hasOwnProperty) {
    args = {}
  }

  return string.replace(nargs, function replaceArg(match, i, index) {
    let result

    if (string[index - 1] === '{' && string[index + match.length] === '}') {
      return i
    } else {
      result = args.hasOwnProperty(i) ? args[i] : null
      if (result === null || result === undefined) {
        return ''
      }

      return result
    }
  })
}

export default template
