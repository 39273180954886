import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatPrice, formatPriceWithCurrency } from 'pmt-utils/currency'

// TODO: handle currency
const formatEntryPrice = entry => {
  // entry price comes as a total price, so we divide quantity to get unit price
  entry.unitPrice = entry.price / (entry.quantity || 1)
  entry.unitPriceFormatted = formatPriceWithCurrency(entry.unitPrice || 0)

  entry.priceFormatted = formatPrice(entry.price)
  entry.priceWithCurrencyFormatted = formatPriceWithCurrency(entry.price)

  return entry
}

const formatEntry = createFormatter(formatEntryPrice)

export const formatEntries = createFormatter(createListFormatter(formatEntry))
