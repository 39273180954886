import invariant from 'invariant'

import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'

const createAction = (type, data, callback = null) => dispatch => {
  if (callback !== null) {
    callback()
  }

  invariant(isNil(data) || isUndefined(data.type), 'data cannot contain a type parameter')

  invariant(!isNil(type), 'type is nil')
  invariant(
    isFunction(dispatch),
    'action is not correctly initialized: const myAction = () => creatAction(...'
  )

  return dispatch({
    type,
    ...data,
  })
}

export default createAction
