import { createSelector } from 'reselect'

import isNil from 'lodash/isNil'

const getState = state => state.i18n

export const isFetchingI18nPhrases = createSelector([getState], state =>
  state.get('isFetching', false)
)

export const getI18nPhrases = createSelector([getState], state => {
  const phrases = state.get('phrases', null)
  if (isNil(phrases)) {
    return null
  }

  return phrases.toJS()
})

export const getCurrentLocale = createSelector([getState], state => state.get('locale', null))
