import React from 'react'

import Page from '../Page'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

/**
 * Fake BackofficePage to be displayed while loading the chunk of the real page
 * see config/routes
 */
const PageLoading = ({ route, classes }) => (
  <Page loading={false}>
    <LoadingBlockWrapper show />
  </Page>
)

export default PageLoading
