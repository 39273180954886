import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import invariant from 'invariant'
import noop from 'lodash/noop'

import TextField from 'pmt-ui/TextField'
import { withStyles } from 'pmt-ui/styles'

import { formatDate } from 'pmt-utils/date'

import Calendar, { CalendarMode } from '../Calendar'
import InlineDate from './InlineDate'

const propDatesAreSame = (a, b) => (!a && !b) || (a && b && moment(a).isSame(b))

const getDateChangedErrorMsg = varName =>
  `${varName} date has changed. Consider using 'momentObject.startOf('day').`

const styles = theme => ({
  calendarContainer: {
    position: 'relative',
  },
  calendar: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
})

/**
 * DateField
 *
 *
 * Note: we don't handle the type 'CalendarMode.multiple' for the moment
 */
class DateField extends React.Component {
  static DEFAULT_DATE_FORMAT = 'dddd DD MMMM YYYY'

  state = {
    displayCalendar: false,
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { props } = this

    //
    // The following options can't be changed !
    // Otherwise we get an infinite loop from flatpickr..
    //

    const defaultDateIsSame = propDatesAreSame(
      nextProps.options.defaultDate,
      props.options.defaultDate
    )

    const minDateIsSame = propDatesAreSame(nextProps.options.minDate, props.options.minDate)

    const maxDateIsSame = propDatesAreSame(nextProps.options.maxDate, props.options.maxDate)

    invariant(defaultDateIsSame, getDateChangedErrorMsg('defaultDate'))

    invariant(minDateIsSame, getDateChangedErrorMsg('minDate'))

    invariant(maxDateIsSame, getDateChangedErrorMsg('maxDate'))
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this

    if (state.displayCalendar !== nextState.displayCalendar) {
      return true
    }

    if (
      props.value !== nextProps.value ||
      (moment.isMoment(nextProps) && nextProps.value.isSame(props.value))
    ) {
      return true
    }

    return false
  }

  handleOpenCalendar = () => {
    this.setState({
      displayCalendar: true,
    })
  }

  handleCloseCalendar = dates => {
    this.setState({
      displayCalendar: false,
    })

    this.handleChange(dates)
  }

  handleChange = dates => {
    // force end of day to the selected end day
    if (this.props.options.mode === CalendarMode.RANGE && dates[1]) {
      dates[1] = dates[1].endOf('day')
    }
    this.props.onChange(dates)
  }

  renderInline() {
    const { options, label, required, value } = this.props

    return (
      <InlineDate
        value={value}
        label={label}
        required={required}
        onChange={this.handleChange}
        options={options}
      />
    )
  }

  renderCalendar() {
    const { classes, onChange, options, label, renderValue, value, ...otherProps } = this.props

    const open = this.state.displayCalendar && !options.inline

    let formattedValue = null
    if (!value) {
      formattedValue = ''
    } else if (renderValue) {
      formattedValue = renderValue(value)
    } else if (!options.mode || options.mode === 'single') {
      formattedValue = formatDate(value, 'DD-MM-YYYY')
    } else if (options.mode === CalendarMode.RANGE) {
      formattedValue = `${formatDate(value[0], 'DD-MM-YYYY')} - ${formatDate(
        value[1],
        'DD-MM-YYYY'
      )}`
    } else {
      invariant(false, 'mode not handled to format value')
    }

    return (
      <div className={classes.calendarContainer}>
        {this.props.children ? (
          this.props.children({
            openCalendar: this.handleOpenCalendar,
            closeCalendar: this.handleCloseCalendar,
          })
        ) : (
          <TextField
            className="u-sizeFullWidth"
            label={label}
            onClick={this.handleOpenCalendar}
            value={formattedValue}
            onChange={noop}
          />
        )}

        {/* TODO: open calendar on modal on small screens */}
        {/* {open && ( */}
        <Calendar
          className={classes.calendar}
          open={open}
          value={value}
          options={{
            ...options,
            ...{
              clickOpens: false,
            },
          }}
          // Note: here onChange is called each time the user select a date, and cause infinite loop,
          // so we use the onClose
          // onChange={this.handleChange}
          onClose={this.handleCloseCalendar}
          {...otherProps}
        />
        {/* )} */}
      </div>
    )
  }

  render() {
    switch (this.props.mode) {
      case DateField.Mode.INLINE:
        return this.renderInline()

      case DateField.Mode.CALENDAR:
        return this.renderCalendar()

      default:
        invariant(false, 'Invalid DateField mode')
    }
  }
}

DateField.Mode = {
  /**
   * Display the date field as a calendar
   * In this mode the prop options is the options of flatpickr
   * @type {String}
   */
  CALENDAR: 'calendar',

  /**
   * Display the date field as three list selector (day, month, year)
   * In this mode the prop option can contains:
   * - defaultDate
   * - minDate
   * - maxDate
   */
  INLINE: 'inline',
}

DateField.defaultProps = {
  mode: DateField.Mode.CALENDAR,
  required: false,
  options: {
    mode: 'single',
  },
}

/**
 * TODO: handle:
 * - validation
 * - required
 * @type {Object}
 */
DateField.propTypes = {
  label: PropTypes.string.isRequired,

  required: PropTypes.bool,

  mode: PropTypes.oneOf([DateField.Mode.CALENDAR, DateField.Mode.INLINE]),

  // param: moment date
  onChange: PropTypes.func.isRequired,

  /**
   * In mode CALENDAR it  can contains all the flatpickr options and hooks
   * - https://chmln.github.io/flatpickr/options/
   * - https://chmln.github.io/flatpickr/events/#hooks
   *
   * Otherwise see DateField.Mode.INLINE (or the corresponding mode)
   */
  options: PropTypes.object.isRequired,

  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default withStyles(styles)(DateField)
