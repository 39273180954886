import { combineReducers } from 'redux'

import { currentTheme } from './currentTheme'

//
// theme reducer.
//
export const theme = combineReducers({
  current: currentTheme,
})
