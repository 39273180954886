import { tr } from 'pmt-modules/i18n'
export const QueryParamType = {
  STRING: 'STRING',
  // can have 'subType' config: 'INTEGER'
  ARRAY: 'ARRAY',
  DATE: 'DATE',
  BOOL: 'BOOL',
  INTEGER: 'INTEGER',
}

export const StatisticsPeriod = {
  TODAY: 'TODAY',
  LAST_SEVEN_DAYS: 'LAST_SEVEN_DAYS',
  LAST_THIRTY_DAYS: 'LAST_THIRTY_DAYS',
  LAST_QUARTER: 'LAST_QUARTER',
  CUSTOM: 'CUSTOM',
}

export const getStatisticsPeriodOptions = () => {
  return [
    {
      label: tr('global.date.today'),
      value: StatisticsPeriod.TODAY,
    },
    {
      label: tr('global.date.last_seven_days'),
      value: StatisticsPeriod.LAST_SEVEN_DAYS,
    },
    {
      label: tr('global.date.last_thirty_days'),
      value: StatisticsPeriod.LAST_THIRTY_DAYS,
    },
    {
      label: tr('global.date.choose_period'),
      value: StatisticsPeriod.CUSTOM,
    },
  ]
}

export const getStatisticsPeriodLabel = period =>
  getStatisticsPeriodOptions().find(periodOption => periodOption.value === period).label

export const getSalesFilterKeys = () => ({
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
})

export const getSalesFilter = () => ({
  hour: {
    label: tr('global.date.hour'),
    filter: 'DD/MM/YYYY HH:mm',
  },
  day: {
    label: tr('global.date.day'),
    filter: 'DD/MM/YYYY',
  },
  week: {
    label: tr('global.date.week'),
    filter: 'DD/MM/YYYY',
  },
  month: {
    label: tr('global.date.month'),
    filter: 'YYYY-MM',
  },
})
