import { redirectTo } from '../routing'

import { createAction } from '../redux'

import {
  createApiCallAction,
  createApiEnumAction,
  // createTestApiCallAction,
} from '../api/utils'

import { getRoute, openExternalLink } from '../routing'
import RestaurantApi from '../api/calls/RestaurantApi'

// NOTE: comment when do not using it for debug
// import { getRestaurantFixture } from '../../api/fixtures/restaurant'

export const GetRestaurantAction = createApiEnumAction('RESTAURANT::GET')

export const redirectToRestaurant = (restaurantId, queryParams) => (dispatch, getState) => {
  return dispatch(redirectTo(getRoute('STORE_LOCATOR_RESTAURANT'), { restaurantId }, queryParams))
}

export const fetchRestaurant = restaurantId =>
  createApiCallAction(GetRestaurantAction, RestaurantApi.getRestaurant(restaurantId), {
    restaurantId,
  })

export const RefreshRestaurantAction = createApiEnumAction('RESTAURANT::REFRESH')

export const refreshRestaurant = (restaurantId, options) =>
  createApiCallAction(RefreshRestaurantAction, RestaurantApi.getRestaurant(restaurantId), {
    restaurantId,
    options,
  })

export const GetRestaurantsAction = createApiEnumAction('RESTAURANT::GET::MULTIPLE')

export const fetchRestaurants = restaurantIds =>
  createApiCallAction(GetRestaurantsAction, RestaurantApi.getRestaurants(restaurantIds), {
    restaurantIds,
  })

export const PostRestaurantAction = createApiEnumAction('RESTAURANT::POST')

export const postRestaurant = data =>
  createApiCallAction(PostRestaurantAction, RestaurantApi.postRestaurant(data), {
    ...data,
  })

export const GetRestaurantListAction = {
  ...createApiEnumAction('RESTAURANT_LIST::GET'),
  RESET: 'RESTAURANT_LIST::RESET',
}

export const fetchRestaurantList = ({ useIP = false, restaurantId = null, limit = 10 }) =>
  createApiCallAction(
    GetRestaurantListAction,
    RestaurantApi.getRestaurantList({ query: { useIP, restaurantId, limit } }),
    {}
  )

export const resetRestaurantList = () => createAction(GetRestaurantListAction.RESET, {})

export const GetRestaurantListByGeoPtAction = createApiEnumAction('RESTAURANT_LIST_BY_GEO_PT::GET')

export const fetchRestaurantListByGeoPt = (latitude, longitude, options = { limit: 10 }) =>
  createApiCallAction(
    GetRestaurantListByGeoPtAction,
    RestaurantApi.getRestaurantListByGeoPt(latitude, longitude, options),
    {
      latitude,
      longitude,
      options,
    }
  )

// using fixtures test. Spain with restrict 4g does not handle our awesome API :(
// export const fetchRestaurant = (restaurantId) => createTestApiCallAction(
//   GetRestaurantAction,
//   true,
//   {
//     response: getRestaurantFixture(),
//     data: {
//       restaurantId,
//     }
//   }
// )

export const RestaurantAction = {
  SET: 'RESTAURANT::SET',
  OPEN_MENU_PDF: 'RESTAURANT::OPEN_MENU_PDF',
  OPEN_WEBSITE: 'RESTAURANT::OPEN_WEBSITE',
}

export const setRestaurant = restaurant => createAction(RestaurantAction.SET, { restaurant })

export const openRestaurantWebsite = restaurant =>
  createAction(RestaurantAction.OPEN_WEBSITE, { restaurant }, () => {
    openExternalLink(restaurant.webSitePub)
  })

export const openRestaurantMenuPdf = restaurant =>
  createAction(RestaurantAction.OPEN_MENU_PDF, { restaurant }, () => {
    openExternalLink(restaurant.menuPdf)
  })
