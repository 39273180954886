import mergeWith from 'lodash/mergeWith'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import { getDefaultAppConfig } from './config'

// mergeWith customizer when array, we don't want to merge, but to replace
const customizer = (objValue, srcValue) =>
  isArray(objValue) && srcValue !== null ? srcValue : srcValue === null ? objValue : undefined

/**
 * merge two config objects, using a customizer.
 * @param {object} defaultData
 * @param {object} currentData
 */
export const mergeConfigObject = (defaultData, currentData) =>
  mergeWith(defaultData || {}, currentData || {}, customizer)

/**
 * add default configs to the given config
 * @param {object} config base config
 */
export const mergeAppConfigWithDefault = (config = {}) => {
  if (isNil(config)) {
    config = {}
  }
  // Note: we don't handle default texts here since it is used to pass a config to our modules
  // who don't requires texts.
  // Plus we need a locale that we don't have here, and we could not change the app config texts if
  // the locale change

  // keys of the app config objects to apply defaults value to
  const appConfigDefaultKeys = ['security', 'front', 'user']

  let updatedConfig = config
  appConfigDefaultKeys.forEach(key => {
    updatedConfig[key] = mergeConfigObject(getDefaultAppConfig()[key], config[key], customizer)
  })

  return updatedConfig
}
