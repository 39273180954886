import { ModalType, createShowDialogAction, createHideDialogAction } from 'pmt-modules/dialog'

export const displayErrorDialog = (errorMessage, options) => createShowDialogAction(
  ModalType.ERROR,
  {
    errorMessage,
    options
  }
)

export const hideErrorDialog = () => createHideDialogAction(
  ModalType.ERROR,
  {}
)
