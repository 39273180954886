import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { API_CALL, REQUEST, TYPES } from 'pmt-modules/api/constants'
import { getOauthClient } from 'pmt-modules/appConfig/selectors'
import { isWebCustomer, isEmbeddedFront } from 'pmt-modules/environment'
import { redirectTo, redirectToExternal, getRoute } from 'pmt-modules/routing'

import { generateUUID } from 'pmt-utils/uuid'
import { CALLBACK_EVENTS, getQueryParam, getCallbackUri, removeUrlParameter } from 'pmt-utils/url'

import {
  RefreshAction,
  retryApiCallAction,
  refreshAction as defaultRefreshActionCreator,
  refreshActionDone,
} from './actions'
import { getRefreshToken } from '../selectors'
import { cleanAuthData } from '../actions'

//
// Inspired by https://github.com/esbenp/redux-refresh-token
//
// see api.js
// Called when a request fail.
// if we have a 401, we try to refresh the token:
// - if fail, the `unauthorized` action is called (see on api.js).
// - if success, we update the token data and re-run the api call.
//
// Note that we handle 401 AFTER the call failed, we should also try to refresh the token before
// it expires.
//

let _refreshPromise = null
let _actions = []

export const clearAttemptRefresh = () => {
  _refreshPromise = null
  _actions = []
}

const defaultIsRefreshCall = action => action[API_CALL][TYPES][0] === RefreshAction.REQUEST

const requestNewAccessToken = ({
  dispatch,
  next,
  refreshActionCreator,
  refreshToken,
  oauthClient,
  callback,
}) => {
  return new Promise((resolve, reject) => {
    dispatch(
      refreshActionCreator(refreshToken, oauthClient, {
        //
        // We trick here to pass a callback to be called when the refresh token call has finished
        //
        then: (state, success, response) => {
          // Refresh was successful
          let refreshPromise = _refreshPromise

          // Refresh was successful
          // call postRefreshToken
          if (success) {
            resolve({
              refreshPromise,
              error: false,
              response,
              actions: _actions,
              dispatch,
            })
          } else {
            resolve({
              refreshPromise,
              error: true,
              response: null,
              actions: _actions,
              dispatch,
            })
          }
        },
      })
    )
  })
}

const postRefreshToken = requestNewAccessTokenResponse => {
  // When the refresh attempt is done, we fire all the actions that have been queued until
  // its completion. If, the refresh promise was unsuccessful we logout the user.

  const { refreshPromise, response, error, actions, dispatch } = requestNewAccessTokenResponse

  if (!error) {
    // no error, run all pending actions
    actions.forEach(action => {
      // We need to update the access token on the request
      if (!isEmpty(action[API_CALL][REQUEST].headers.authorization)) {
        action[API_CALL][REQUEST].headers.authorization = `Bearer ${response.access_token}`
      }
      dispatch(retryApiCallAction(action))
    })
  } else {
    // error, dispatch the error that occurred when refreshing the token
    if (!isNull(refreshPromise)) {
      dispatch(refreshPromise.failureAction(error))
    }
  }

  clearAttemptRefresh()
  dispatch(refreshActionDone())
}

const attemptRefresh = settings => {
  const {
    action,
    failureAction, // action to use when the refresh token api call failed
    isRefreshCall = defaultIsRefreshCall,
    next,
    refreshActionCreator = defaultRefreshActionCreator,
    getState,
    dispatch,
    token,
  } = settings

  //
  // Take the ApiError of the failed request
  //
  return apiError => {
    const response = apiError.response
    // keep both writings for retro compatibility
    let accessToken = null
    let callbackUri = null
    if (isEmbeddedFront()) {
      accessToken = getQueryParam('accessToken') || getQueryParam('access_token')
      callbackUri = getQueryParam('callbackUri') || getQueryParam('callback_uri')
    }

    if (!response) {
      return dispatch(failureAction(apiError))
    }

    // The API call returned unauthorized user (access token is expired)

    // specific 401 for test mode handling, do not want to refresh a token, but redirect to login page
    // 401 = we are not testing user OR we are not logged in, redirect to login page
    if (
      !response.ok &&
      response.status === 401 &&
      apiError.errorCode === 'TEST_MODE_UNAUTHORIZED'
    ) {
      dispatch(
        redirectTo(getRoute('LOGIN'), null, {
          redirectTo: window.location.toString(),
          forTestMode: true,
        })
      )
    } else if (
      // TODO: && error contains "The access token provided has expired." For now the API only gives
      // message: "Auth failed". We should modify the API first.
      !response.ok &&
      response.status === 401 &&
      // The refresh endpoint might return 401, so we skip the check here
      // otherwise we get stuck in an infinite loop
      !isRefreshCall(action) &&
      // We should not run the refresh flow when no token was given to begin with
      // (for instance Forgot Password, Login etc.)
      typeof token === 'string' &&
      token.length > 0 &&
      // if callbackUri is not null, we don't want to attempt refresh on our own
      callbackUri === null
    ) {
      const refreshToken = getRefreshToken(getState())
      const oauthClient = getOauthClient(getState())

      if (!refreshToken) {
        // no refresh token..
        dispatch(failureAction(apiError))
      } else {
        // We check if there is already dispatched a call to refresh the token,
        // if so we can simply queue the call until the refresh request completes
        _actions.push(action)

        // no refresh token action ongoing, we create it
        if (isNull(_refreshPromise)) {
          _refreshPromise = requestNewAccessToken({
            dispatch,
            next,
            refreshActionCreator,
            refreshToken,
            oauthClient,
          }).then(postRefreshToken)

          _refreshPromise.id = generateUUID()
          _refreshPromise.failureAction = failureAction
        }

        return _refreshPromise
      }
      // we cannot refresh token on our own, the user has to login or let the callback uri handle the refresh
    } else if (!response.ok && (response.status === 401 || response.status === 403)) {
      // on web customer, when a callback has been given, we let the author made the refresh
      if (isWebCustomer() && callbackUri) {
        redirectToExternal(getCallbackUri(callbackUri, CALLBACK_EVENTS.INVALID_ACCESS_TOKEN))
        return
      }

      // otherwise we remove access token provided from url and redirect user to login page
      if (accessToken !== null || callbackUri !== null) {
        const location = document.location.pathname + document.location.search
        let redirectToParam = removeUrlParameter(location, 'accessToken')
        redirectToParam = removeUrlParameter(location, 'access_token')

        dispatch(cleanAuthData())
        if (isWebCustomer()) {
          dispatch(
            redirectTo(getRoute('LOGIN'), null, {
              redirectTo: redirectToParam || window.location.toString(),
            })
          )
        } else {
          dispatch(redirectTo(getRoute('LOGIN'), null, { redirectTo: redirectToParam }))
        }
      } else {
        dispatch(failureAction(apiError))
      }
    }

    // refreshMiddlewareFailure
  }
}

export default attemptRefresh
