//
// To create a new form:
// - add the form constant name on FormType.js (follow the `Tip: NEW_FORM_ABOVE`)
// - register the form on the front (config/forms.js)
//
// Note:
// You can set the `disableFormDevData` query parameter to false to disable dev default data.
// see `createForm`
//
import { getFormReducer, getFormModifier } from './config'
import { FormAction } from './actions'

import { DataCatcherAction, dataCatcherFormReducer } from '../dataCatcher'

import { FORM_CUSTOM_ACTION } from './createFormCustomAction'
import { DEFAULT_FORM_OPTIONS } from './constants'

export FormType from './FormType'
export * from './config'
export * from './validation'
export * from './actions'
export * from './selectors'
export * from './components'
export * from './createForm'

const dataModifier = (data, formType) => {
  const modifier = getFormModifier(formType)
  return !modifier ? data : modifier(data)
}

let DEFAULT_STATE = {}
export const formReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FormAction.CREATE:
      return state.mergeIn([action.formType], {
        formData: dataModifier(action.data, action.formType),
        options: DEFAULT_FORM_OPTIONS,
      })

    case FormAction.UPDATE:
      return state.mergeIn([action.formType], {
        formData: dataModifier(action.data, action.formType),
      })

    case FormAction.RESET:
      const resetForm = (state, action) => {
        if (action.withDefaultData) {
          return state.mergeIn([action.formType], {
            formData: dataModifier(action.data, action.formType),
            options: action.options,
          })
        }

        return state.mergeIn([action.formType], {
          formData: dataModifier(action.data, action.formType),
        })
      }
      return resetForm(state, action)

    case FormAction.UPDATE_OPTIONS:
      return state.mergeIn([action.formType, 'options'], action.options)

    case FormAction.ACTIVATION:
      return state.mergeIn([action.formType], {
        options: {
          isActivated: action.isActivated,
        },
      })

    case FORM_CUSTOM_ACTION:
      const reducer = getFormReducer(action.formType)
      const data = reducer(state.get(action.formType).toJS(), action)
      return state.mergeIn([action.formType], {
        formData: dataModifier(data.formData, action.formType),
        options: data.options
      })

    case DataCatcherAction.POST_CATCHED_DATA:
      return dataCatcherFormReducer(state, action)

    default:
      return state
  }
}
