import { createFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { formatEntries } from './entry'

const formatTableNumber = check => {
  // TODO
  check.tableNumberLabel = `Table ${check.tableNumber}`
  return check
}

const formatTotal = check => {
  check.totalFormatted = formatPriceWithCurrency(check.total, check.currency)
  check.tips = check.tips || 0
  check.totalWithoutTipsFormatted = formatPriceWithCurrency(
    check.total - check.tips,
    check.currency
  )
  check.outstandingBalanceFormatted = formatPriceWithCurrency(check.outstandingBalance)

  check.hasOutstandingBalance = check.outstandingBalance > 0

  check.hasOverPayment = check.outstandingBalance < 0
  check.hasUnderPayment = check.outstandingBalance > 0 && check.outstandingBalance !== check.total

  if (check.hasOverPayment) {
    check.outstandingBalanceOverPaymentFormatted = formatPriceWithCurrency(
      -check.outstandingBalance
    )
  }
  if (check.hasUnderPayment) {
    check.outstandingBalanceUnderPaymentFormatted = formatPriceWithCurrency(
      check.outstandingBalance
    )
  }
  return check
}

export const formatCheck = createFormatter(
  formatTableNumber,
  formatTotal,
  createSubObjectFormatter('entries', formatEntries)
)
