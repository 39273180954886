import React from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import isFunction from 'lodash/isFunction'
import { connect } from 'react-redux'
import { isBo } from 'pmt-modules/environment'

import { fetchRestaurantsGroup } from '../actions'
import {
  makeGetRawRestaurantsGroup,
  makeGetRestaurantsGroup,
  makeGetRestaurantsGroupError,
  makeIsFetchingRestaurantsGroup,
} from '../selectors'

/**
 * @specs N/A
 *
 * A HOC that give fetch the restaurantsGroup and pass it to the children
 *
 * Requirements:
 * - restaurantsGroupId
 *
 * see `withRestaurantsGroup`
 *
 */
class RestaurantsGroupContainer extends React.Component {
  // TODO: on api consumer list View, we load the same group multiple times.
  // should we load on componentDidUpdate instead of constructor?
  constructor(props) {
    super(props)

    const isFetching = this.props.isFetchingRestaurantsGroup
    const forceReload = this.props.forceReload === true

    // handle when we already loaded the restaurants group, but we loaded it without its children,
    // and now we want it with its children
    const loadChildren =
      !isNull(this.props.restaurantsGroup) &&
      this.props.restaurantsGroup.children === null &&
      this.props.options.loadChildren === true

    const notLoadedYet = isNull(this.props.restaurantsGroup)

    if (
      // do not try to fetch it if we already doing it
      !isFetching &&
      (forceReload || loadChildren || notLoadedYet)
    ) {
      this.loadRestaurantsGroup(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props
    if (
        (nextProps.restaurantsGroupId !== props.restaurantsGroupId &&
      ((!props.restaurantsGroup || props.restaurantsGroup.id) && nextProps.restaurantsGroupId))
        || (props.options.withArchived !== nextProps.options.withArchived)
      // TODO: handle props.options.loadChildren !== nextProps.options.loadChildren
    ) {
      this.loadRestaurantsGroup(nextProps)
    }
  }

  loadRestaurantsGroup(props) {
    props.fetchRestaurantsGroup(props.restaurantsGroupId, {
      withAuthorization: props.options.withAuthorization,
      loadChildren: props.options.loadChildren,
      withArchived: props.options.withArchived,
    })
  }

  render() {
    const {
      RestaurantsGroupWrappedComponent,
      isFetchingRestaurantsGroup,
      rawRestaurantsGroup,
      restaurantsGroup,
      restaurantsGroupError,
      restaurantsGroupId,
      children,
      ...otherProps
    } = this.props

    if (isFunction(children)) {
      return children({
        isFetchingRestaurantsGroup,
        rawRestaurantsGroup,
        restaurantsGroup,
        restaurantsGroupError,
      })
    }

    return (
      <RestaurantsGroupWrappedComponent
        isFetchingRestaurantsGroup={isFetchingRestaurantsGroup}
        rawRestaurantsGroup={rawRestaurantsGroup}
        restaurantsGroup={restaurantsGroup}
        restaurantsGroupError={restaurantsGroupError}
        restaurantsGroupId={restaurantsGroupId}
        {...otherProps}
      />
    )
  }
}

RestaurantsGroupContainer.defaultProps = {
  options: {
    // default false, to avoid missing it. Default true for Bo because we always retrieve a
    // RestaurantsGroup while we are logged in. On other fronts, we retrieve restaurants group for
    // anonymous users too.
    withAuthorization: isBo(),

    loadChildren: false,
  },
}

RestaurantsGroupContainer.propTypes = {
  fetchRestaurantsGroup: PropTypes.func.isRequired,
  isFetchingRestaurantsGroup: PropTypes.bool,
  restaurantsGroup: PropTypes.object,

  restaurantsGroupId: PropTypes.oneOfType([
    PropTypes.string, // the id or 'mine'
    PropTypes.number, // the id
  ]),

  /**
   * Additional options, used for the component:
   * - withAuthorization -> should be use the default Authorization or not ?
   * Must be set to false when on a non logged in page.
   */
  options: PropTypes.object,
}

const makeMapStateToProps = () => {
  const getRawRestaurantsGroup = makeGetRawRestaurantsGroup()
  const getRestaurantsGroup = makeGetRestaurantsGroup()
  const getRestaurantsGroupError = makeGetRestaurantsGroupError()
  const isFetchingRestaurantsGroup = makeIsFetchingRestaurantsGroup()

  const mapStateToProps = (state, props) => ({
    restaurantsGroup: getRestaurantsGroup(state, props),
    restaurantsGroupError: getRestaurantsGroupError(state, props),
    rawRestaurantsGroup: getRawRestaurantsGroup(state, props),
    isFetchingRestaurantsGroup: isFetchingRestaurantsGroup(state, props),
  })
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchRestaurantsGroup,
  }
)(RestaurantsGroupContainer)
