// https://github.com/react-ga/react-ga - previous version for Universal Analytics
//import ReactGA from 'react-ga'
// https://www.npmjs.com/package/react-ga4 - new version for Google Analytics 4
import ReactGA from 'react-ga4'
import invariant from 'invariant'
import concat from 'lodash/concat'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import { getFrontSessionId } from 'pmt-modules/frontSession'

import { getAnalyticsConfig, registerAnalyticsConfig } from './config'
import { cleanUrls } from './utils'

/**
 * Config is made in [project]/src/config/analytics.js
 * It will be merged with api consumer settings if set
 * config: {
 *   waitForAppConfig: true,
 *   identifiers: [
 *     {
 *       trackingId: 'UA-XXXX-XX',
 *       debug: true,
 *       gaOptions: {
 *         cookieDomain: 'auto',
 *         name: '...',
 *       },
 *     },
 *   ],
 * }
 *
 * - waitForAppConfig, default: true. Set to false for the fronts that do not load the app config
 *  (e.g: backoffice)
 */

export * from './config'

let _isInitialized = false

// deprecated - was for react-ga, not useful for react-ga4 who handle multiple trackers
const getTrackersNames = () =>
  getAnalyticsConfig().identifiers.map(identifier => {
    invariant(!isNil(identifier.gaOptions), 'no identifier gaOptions found')
    return identifier.gaOptions.name
  })

// deprecated - was for UA, not tested with GA4
export const pluginRequire = name => {
  forEach(getTrackersNames(), trackerName => {
    ReactGA.ga(`${trackerName}.require`, name)
  })
}
// deprecated - was for UA, but the object ReactGA.plugin doesn't seem to exist with GA4
// the only plugin we used was 'ec' (ecommerce) but it seems integrated in GA4 (no plugin needed)
export const pluginExecute = (pluginName, action, ...args) => {
  forEach(getTrackersNames(), trackerName => {
    ReactGA.plugin && ReactGA.plugin.execute(`${trackerName}.${pluginName}`, action, ...args)
  })
}

// for UA
const sendCheckoutProgress = stepNumber => {
  ReactGA.event('checkout_progress', { checkout_step: stepNumber })
}

// for UA
const sendCheckoutOption = (stepNumber, optionName, optionValue) => {
  ReactGA.event('set_checkout_option', {
    checkout_step: stepNumber,
    checkout_option: optionName,
    value: optionValue,
  })
}

export const sendViewItem = formattedItem => {
  // UA and GA4
  ReactGA.event('view_item', { items: [formattedItem] })
}

export const sendAddToCart = formattedItem => {
  // UA and GA4
  ReactGA.event('add_to_cart', { items: [formattedItem] })
}

export const sendRemoveFromCart = formattedItem => {
  // UA and GA4
  ReactGA.event('remove_from_cart', { items: [formattedItem] })
}

export const sendViewCart = (formattedItems, stepNumber) => {
  // for GA4
  ReactGA.event('view_cart', { items: formattedItems })
  // for UA
  if (stepNumber) {
    sendCheckoutProgress(stepNumber)
  }
}

export const sendCartSubmitted = (formattedItems, stepNumber) => {
  // for GA4
  ReactGA.event('begin_checkout', { items: formattedItems })
  // for UA
  if (stepNumber) {
    sendCheckoutProgress(stepNumber)
  }
}

export const sendLogin = stepNumber => {
  // for GA4
  ReactGA.event('login')
  // for UA
  if (stepNumber) {
    sendCheckoutProgress(stepNumber)
  }
}

export const sendAddDeliveryInfo = (formattedItems, stepNumber) => {
  // for GA4
  ReactGA.event('add_shipping_info', { items: formattedItems })
  // for UA
  if (stepNumber) {
    sendCheckoutProgress(stepNumber)
  }
}

export const sendAddPaymentInfo = (formattedItems, paymentMode, stepNumber) => {
  // for GA4
  ReactGA.event('add_payment_info', {
    payment_type: paymentMode,
    items: formattedItems,
  })
  // for UA
  if (stepNumber) {
    sendCheckoutOption(stepNumber, 'payment_type', paymentMode)
  }
}

export const sendPaymentSucceeded = (formattedItems, transactionId, monetaryValue, currency) => {
  ReactGA.event('purchase', {
    items: formattedItems,
    transaction_id: transactionId,
    value: monetaryValue,
    currency: currency,
  })
}

export const sendPaymentSubmitted = (formattedItems, stepNumber) => {
  // for GA4 - custom event
  ReactGA.event('payment_submitted', { items: formattedItems })
  // for UA
  if (stepNumber) {
    sendCheckoutProgress(stepNumber)
  }
}

export const sendEvent = event => {
  ReactGA.event(event)
}

export const sendPageView = page => {
  ReactGA.ga(`set`, 'frontSessionId', getFrontSessionId())
  ReactGA.ga(`set`, 'location', window.location.href)
  ReactGA.ga(`set`, 'page', cleanUrls(page))
  ReactGA.send({ hitType: 'pageview', page: cleanUrls(page) })
}

const handleInit = (config, override) => {
  if (override && override.identifiers) {
    config.identifiers = concat(config.identifiers, override.identifiers)
  }

  registerAnalyticsConfig(config)

  ReactGA.initialize(config.identifiers, config.options)

  EventManager.dispatch(EventManager.Events.ON_ANALYTICS_INIT)

  // set the first page just after init
  sendPageView(window.location.pathname)

  _isInitialized = true

  ReactGA.ga(`set`, 'frontSessionId', getFrontSessionId())
}

export const initializeAnalytics = appOptions => {
  const config = appOptions.analytics

  invariant(!isNil(config), 'no analytics config found')
  invariant(!isNil(config.identifiers), 'no identifiers found on analytics config')

  // some fronts does not retrieve the app config (e.g: backoffice). We use this config to init
  // the analytics config directly.
  if (config.waitForAppConfig === false) {
    handleInit(config, null)
  } else {
    EventManager.addListener(EventManager.Events.ON_GET_APP_CONFIG_SUCCESS, ({ data }) => {
      data.appConfig.front.analytics
        ? handleInit(config, data.appConfig.front.analytics)
        : handleInit(config, null)
    })
  }

  EventManager.addListener(EventManager.Events.ON_HISTORY_CHANGE, ({ data }) => {
    // the ON_HISTORY_CHANGE event is send before we initializeAnalytics (ON_GET_APP_CONFIG_SUCCESS
    // event)
    if (!_isInitialized) {
      console.warn('History change event ignored, analytics not initialized')
      return
    }

    sendPageView(data.location.pathname)
  })
}
