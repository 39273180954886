import { goBack } from 'pmt-modules/routing'
import { createMiddleware } from 'pmt-modules/redux'
import { isOrderPlugin } from 'pmt-modules/environment'

import { DialogAction } from './actions'

const dialogMiddleware = createMiddleware(DialogAction.HIDE_DIALOG_BACK, ({ dispatch, action }) => {
  if (isOrderPlugin()) {
    dispatch(goBack())
  }
})

export const dialogMiddlewares = [dialogMiddleware]
