import React from 'react'
import { connect } from 'react-redux'

import Dialog from 'pmt-ui/Dialog'
import { withStyles } from 'pmt-ui/styles'

import { hideDialogAction } from '../actions'
import { getDialogViewForType } from '../config'
import { getDialog } from '../selectors'

/**
 * TODO: use portal?
 * https://github.com/cloudflare/react-gateway
 *
 * The root component of any modal
 */
const DialogRoot = ({ dialog, hideDialogAction }) => {
  if (!dialog.type) {
    return null
  }

  const SpecificModal = getDialogViewForType(dialog.type)

  class generateDialog extends React.Component {
    render() {
      const { classes } = this.props

      return SpecificModal.DialogRootProps && SpecificModal.DialogRootProps.isComplex ? (
        <SpecificModal
          {...dialog.props}
          onClose={() => {
            hideDialogAction(dialog.type)
          }}
        >
          {({ dialogContent, ...otherProps }) => (
            <Dialog
              open
              // false to handle onClose when clicking outside.
              // handle legacy `ignoreBackdropClick` renamed to `disableBackdropClick`
              disableBackdropClick={
                (SpecificModal.DialogRootProps &&
                  SpecificModal.DialogRootProps.ignoreBackdropClick) ||
                false
              }
              onClose={() => {
                hideDialogAction(dialog.type)
              }}
              {...SpecificModal.DialogRootProps}
              classes={classes}
              {...otherProps}
            >
              {React.cloneElement(dialogContent)}
            </Dialog>
          )}
        </SpecificModal>
      ) : (
        <Dialog
          open
          // false to handle onClose when clicking outside.
          // handle legacy `ignoreBackdropClick` renamed to `disableBackdropClick`
          disableBackdropClick={
            (SpecificModal.DialogRootProps && SpecificModal.DialogRootProps.ignoreBackdropClick) ||
            false
          }
          onClose={() => {
            hideDialogAction(dialog.type)
          }}
          {...SpecificModal.DialogRootProps}
          classes={classes}
        >
          <SpecificModal
            // add shortcut to hide the dialog
            onClose={() => {
              hideDialogAction(dialog.type)
            }}
            {...dialog.props}
          />
        </Dialog>
      )
    }
  }

  const DialogWithStyles = withStyles(SpecificModal.DialogStyles || {})(generateDialog)

  return <DialogWithStyles />
}

const mapStateToProps = state => ({
  dialog: getDialog(state),
})

export default connect(
  mapStateToProps,
  {
    hideDialogAction,
  }
)(DialogRoot)
