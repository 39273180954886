import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Dialog, { DialogContent, DialogTitle } from 'pmt-ui/Dialog'

const styles = theme => ({
  dialogPaper: {
    height: '80vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '20vw',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
})

/**
 * Display the ListChooser as a dialog.
 * It is displayed as a dialog on small screens.
 * It can be forced using the `ListChooser.type` prop with `ListChooserType.DIALOG` value
 */
const DialogView = ({ anchorEl, open, header, content, classes, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    onBackdropClick={onClose}
    classes={{
      paper: classes.dialogPaper,
    }}
    disableBackdropClick={false}
    disableEscapeKeyDown={false}
  >
    <DialogTitle>{header}</DialogTitle>

    <DialogContent
      classes={{
        root: classes.dialogContent,
      }}
    >
      {content}
    </DialogContent>
  </Dialog>
)

export default withStyles(styles)(DialogView)
