import forEach from 'lodash/forEach'
import Events from './Events'

/**
 * Manage dispatch Events and plugins custom events
 */
class EventManager {
  /**
   * key: event
   * value: array of events
   */
  listeners = {}

  /**
   * Shortctut to Events.
   * Also contains the plugins events. (see registerEvents)
   */
  Events = { ...Events }

  addListener(event, fn) {
    this.listeners[event] = [...(this.listeners[event] || []), fn]

    // TODO:
    // - verify fn is a function
    // - verify event is listed events or plugins events
  }

  dispatch(event, data = {}) {
    const listeners = this.listeners[event]

    // TODO: dev log

    if (listeners !== undefined) {
      listeners.forEach(listener => {
        listener({
          type: event,
          data,
        })
      })
    }
  }

  registerEvents(events) {
    // TODO: in dev, verify that there is no collusion
    this.Events = { ...this.Events, ...events }
  }

  addListeners(listeners) {
    forEach(listeners, (fn, event) => {
      this.addListener(event, fn)
    })
  }
}

const EventManagerInstance = new EventManager()

// globalize EventManagerInstance
window.PMTEventManager = EventManagerInstance

// EventManager is a Singleton
export default EventManagerInstance
