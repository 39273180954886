import { createAction } from '../redux'

import {
  getFormDefaultData,
  getFormDefaultOptions,
} from './config'

export const FormAction = {
  CREATE: 'FORM::CREATE',
  UPDATE: 'FORM::UPDATE',
  RESET: 'FORM::RESET',
  ACTIVATION: 'FORM::ACTIVATION',
  UPDATE_OPTIONS: 'FORM::OPTIONS::UPDATE'
}

/**
 *
 * @param {string} formType The FormType
 * @param {any} data The form data
 */
export const initForm = (formType, data) => createAction(
  FormAction.CREATE,
  {
    formType,
    data,
  }
)

/**
 *
 * @param {string} formType The FormType
 * @param {any} data The form data
 */
export const updateForm = (formType, data) => createAction(
  FormAction.UPDATE,
  {
    formType,
    data,
  }
)

/**
 *
 * @param {string} formType The FormType
 * @param {any} data The form data
 */
export const resetForm = (formType, data, options) => createAction(
  FormAction.RESET,
  {
    withDefaultData: false,
    formType,
    data,
    options,
  }
)

/**
 *
 * @param {string} formType The FormType
 * @param {any} data The form data
 */
export const resetFormWithDefaultData = (formType) => createAction(
  FormAction.RESET,
  {
    withDefaultData: true,
    formType,
    data: getFormDefaultData(formType),
    options: getFormDefaultOptions(formType),
  }
)


//
//
//

export const setFormActivation = (formType, isActivated) => createAction(
  FormAction.ACTIVATION,
  {
    formType,
    isActivated,
  }
)

/**
 * update options of a form
 */
export const updateFormOptions = (formType, options) => createAction(
  FormAction.UPDATE_OPTIONS,
  {
    formType,
    options,
  }
)
