import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Button from 'pmt-ui/Button'

const styles = theme => ({
  root: {
    margin: `${theme.spacing(1)} auto`,
  },
})

const Pagination = ({ paging, onLoadMore, classes }) => {
  if (paging && paging.cursors && paging.cursors.after) {
    return (
      <Button className={classes.root} onClick={() => onLoadMore(paging.cursors.after)}>
        {tr('global.load_more')}
      </Button>
    )
  }
  return null
}
export default withStyles(styles)(Pagination)
