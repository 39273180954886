import { createMuiTheme, dark, light, getLuminance } from 'pmt-ui/styles'

import orange from 'pmt-ui/colors/orange'
import red from 'pmt-ui/colors/red'
import grey from 'pmt-ui/colors/grey'
import lightGreen from 'pmt-ui/colors/lightGreen'

import { getQueryParam } from 'pmt-utils/url'

const PMTColors = {
  red400: red[400],
  orange300: orange[300],
  lightGreen400: lightGreen[400],
  grey500: grey[500],
  grey50: grey[50],
  white: '#fff',

  middlegrey: '#919ca7',
  lightgrey: '#eceff1', // efeff4

  pageTitleColor: '#262626',

  black: 'black',
}

const primary = {
  main: '#9dc212',
}

const getPMTColor = color => PMTColors[color]

const getPMTColors = () => PMTColors

const muiTheme = createMuiTheme({
  palette: {
    type: getQueryParam('theme', window.location.uri) || 'light', // Switching the dark mode on is a single property value change.
    primary,
    secondary: {
      main: '#00A695',
    },
    contrastThreshold: 0.5,
    // https://github.com/callemall/material-ui/issues/8183
    // override getContrastText function to have a white color on green buttons
    // TODO : check the evolution of the issue above
  },
  pmt: {
    colors: getPMTColors(),
    status: {
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      inactive: getPMTColor('grey500'),
      valid: getPMTColor('lightGreen400'),
      info: getPMTColor('blue700'),
    },
    alert: {
      caption: getPMTColor('grey500'),
      info: getPMTColor('blue700'),
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      success: getPMTColor('lightGreen400'),
    },
    circularProgress: {
      width: 50,
    },
    errorLabel: {
      marginTop: 20,
      color: getPMTColor('red400'),
    },
    separator: {
      400: '#cacaca',
    },
    loading: {
      color: primary.main,
    },
    typography: {
      fonts: {
        124: {
          style: {
            fontSize: 12,
            fontWeight: 400,
            margin: 0,
          },
        },
        144: {
          style: {
            fontSize: 14,
            fontWeight: 400,
            margin: 0,
          },
        },
        147: {
          style: {
            fontSize: 14,
            fontWeight: 700,
            margin: 0,
          },
        },
        164: {
          style: {
            fontSize: 16,
            fontWeight: 400,
            margin: 0,
          },
        },
        167: {
          style: {
            fontSize: 16,
            fontWeight: 700,
            margin: 0,
          },
        },
        240: {
          style: {
            fontSize: 24,
            fontWeight: 400,
            margin: 0,
          },
        },
      },
    },
  },
})

const theme = {
  muiTheme,
}

theme.muiTheme.palette.getContrastText = color => {
  if (getLuminance(color) <= theme.muiTheme.palette.contrastThreshold) {
    return dark.text.primary
  } else {
    return light.text.primary
  }
}

export default theme
