import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isArray from 'lodash/isArray'

import invariant from 'invariant'

const createFormatter = (...functions) => {
  return (data, props = {}) => {
    invariant(
      isNil(props) || typeof props === 'object',
      `createFormatter: format props must be an object not ${props}`
    )

    if (isNil(data)) {
      return null
    }

    let res = isArray(data) ? [...data] : { ...data }

    if (isEmpty(functions)) {
      return res
    }

    functions.forEach(func => {
      invariant(isFunction(func), 'invalid parameter pass to createFormatter')

      const funcRes = func(res, props)

      invariant(!isNil(funcRes), `[formatter] invalid return, data is nil on ${func}`)

      res = funcRes
    })

    return res
  }
}

export default createFormatter
