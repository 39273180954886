import invariant from 'invariant'
import isNil from 'lodash/isNil'
import findKey from 'lodash/findKey'

import { HistoryType } from './constants'
import { TOP_UP_STATUS } from '../topUp/constants'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

import HistoryApi from '../api/calls/HistoryApi'

export const GetHistoryAction = createApiEnumAction('USER::HISTORY::GET')

const getHistoryApiForType = (userId, historyType, data, cursor, limit) => {
  switch (historyType) {
    case HistoryType.ORDER:
      return HistoryApi.getOrders(userId, data, cursor, limit)
    case HistoryType.BOOKING:
      return HistoryApi.getBookings(userId, data, cursor, limit)
    case HistoryType.PAYMENT:
      return HistoryApi.getPayments(userId, data, cursor, limit)
    case HistoryType.TOP_UP:
      return HistoryApi.getTopUps(
        userId,
        data,
        cursor,
        limit,
        findKey(TOP_UP_STATUS, o => o === TOP_UP_STATUS.SUCCEEDED)
      )
    case HistoryType.USER_TRANSACTIONS:
      return HistoryApi.getPaymentsUserCheck(userId, data, cursor, limit)
    case HistoryType.TRANSACTIONS:
      // data:
      // - userAccountId
      invariant(!isNil(data.userAccountId), `required user account id`)
      return HistoryApi.getTransactions(userId, data, cursor, limit)
    default:
      invariant(false, `invalid historyType ${historyType}`)
  }
}

/**
 * @param  {string} userId
 * @param  {[type]} historyType
 * @param  {object} props        must contain:
 *                                - restaurantIds: the ids of the restaurants we already loaded
 * @param  {String} [cursor=''] the next cursor
 * @param  {Number} [limit=10]  the limit of items to retrieve
 */
export const fetchUserHistory = (userId, historyType, props, cursor = '', limit = 10) =>
  createApiCallAction(
    GetHistoryAction,
    getHistoryApiForType(userId, historyType, props, cursor, limit),
    {
      userId,
      historyType,
      props,
      cursor,
      limit,
    }
  )
