import { createMuiTheme } from 'pmt-ui/styles'

export * from './theme'
export * from './selectors'
export * from './constants'
export { changeCurrentTheme } from './currentTheme'

//
// {
//    muiTheme: {}, // see https://material-ui-1dab0.firebaseapp.com/customization/themes/#configuration-variables
// }
//
// contains the theme defined on the appOptions
let _theme = {}

export const registerTheme = (theme) => {
  _theme = theme
}

export const getRegisteredTheme = () => (
  _theme
)

export const getRegisteredMuiTheme = () => (
  createMuiTheme(getRegisteredTheme().muiTheme)
)
