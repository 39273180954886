import { feedbackAnswerReducer } from 'pmt-modules/feedback/answer'
import { feedbackCampaignReducer } from 'pmt-modules/feedback/campaign'
import { restaurantReducer } from 'pmt-modules/restaurant'
import { restaurantsGroupReducer } from 'pmt-modules/restaurantsGroup'

export default {
  feedbackAnswer: feedbackAnswerReducer,
  feedbackCampaign: feedbackCampaignReducer,
  restaurant: restaurantReducer,
  restaurantsGroup: restaurantsGroupReducer,
}
