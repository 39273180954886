import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { compose } from 'redux'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import keys from 'lodash/keys'

import LoadingBlock from 'pmt-ui/LoadingBlock'
import Paper from 'pmt-ui/Paper'
import TrendingUp from 'pmt-ui/svg-icons/action/trending-up'
import Tabs, { Tab } from 'pmt-ui/Tabs'
import Typography from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  header: {
    background: theme.pmt.colors.lightGreen400,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
    position: 'relative',
  },
  icon: {
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
  },
  title: {
    color: theme.pmt.colors.white,
    lineHeight: '48px',
  },
  tab: {
    color: theme.pmt.colors.white,
    minWidth: 50,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  contentHasNoData: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Graph = ({
  changeFilter,
  classes,
  datasets,
  icon,
  filters,
  filtersState,
  labels,
  options,
  name,
  type,
  isFetching,
}) => {
  const hasData = !isEmpty(datasets) && !isEmpty(datasets[0].data)

  return (
    <Paper>
      <div className={clsx(classes.header, 'u-overflowHidden')}>
        <Typography className={`${classes.title} u-floatLeft`} variant="subtitle1" align="left">
          {React.cloneElement(icon, {
            className: classes.icon,
          })}
          {name}
        </Typography>

        {!isEmpty(filters) &&
          !isNull(changeFilter) && (
            <Tabs
              className="u-floatRight"
              value={filtersState}
              onChange={(e, filter) => changeFilter(filter)}
            >
              {keys(filters).map(filter => (
                <Tab
                  key={filter}
                  className={classes.tab}
                  value={filter}
                  label={filters[filter].label}
                />
              ))}
            </Tabs>
          )}
      </div>

      <div className={clsx(classes.content, { [classes.contentHasNoData]: !hasData })}>
        {isFetching ? (
          <LoadingBlock classes={{ loadingBlockContainer: classes.progress }} show />
        ) : !hasData ? (
          <div>{tr('global.statistics.graph.no_data')}</div>
        ) : (
          React.cloneElement(type, {
            data: {
              labels,
              datasets,
            },
            options,
          })
        )}
      </div>
    </Paper>
  )
}

Graph.propTypes = {
  changeFilter: PropTypes.func,
  datasets: PropTypes.array.isRequired,
  icon: PropTypes.element,
  filters: PropTypes.object,
  filtersState: PropTypes.string,
  labels: PropTypes.array.isRequired,
  options: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.element.isRequired,
}

Graph.defaultProps = {
  icon: <TrendingUp />,
}

export default compose(withStyles(styles))(Graph)
