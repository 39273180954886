import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getAuthRestaurantData = state => state.entities.authRestaurant

export const getAuthRestaurantError = createSelector(
  [getAuthRestaurantData],
  authRestaurantData => {
    const error = authRestaurantData.get('error')
    if (isNil(error)) {
      return null
    }
    return error.toJS()
  }
)

export const isFetchingAuthRestaurant = createSelector(
  [getAuthRestaurantData],
  authRestaurantData => {
    return authRestaurantData.get('isFetching') || false
  }
)

export const haveAuthRestaurantCredentials = createSelector(
  [getAuthRestaurantData],
  authRestaurantData => {
    return authRestaurantData.get('auth') !== null
  }
)

export const isLoggedIn = createSelector([getAuthRestaurantData], authRestaurantData => {
  const auth = authRestaurantData.get('auth')

  return auth !== null
})

export const getBasicToken = createSelector([getAuthRestaurantData], authRestaurantData => {
  if (!authRestaurantData) {
    return null
  }

  const authRestaurantState = authRestaurantData.get('auth')

  if (isNil(authRestaurantState)) {
    return null
  }

  return authRestaurantState
})

export const getApiConsumer = createSelector([getAuthRestaurantData], authRestaurantData => {
  if (isNil(authRestaurantData)) {
    return null
  }

  const apiConsumer = authRestaurantData.get('apiConsumer')

  if (isNil(apiConsumer)) {
    return null
  }

  return apiConsumer
})

export const getRestaurantId = createSelector([getAuthRestaurantData], authRestaurantData => {
  if (isNil(authRestaurantData)) {
    return null
  }

  const restaurantId = authRestaurantData.get('restaurantId')

  if (isNil(restaurantId)) {
    return null
  }

  return restaurantId
})
