import Immutable from 'immutable'
import { createReducer, createSimpleReducer } from 'pmt-modules/redux'

import {
  GetRestaurantsGroupAction,
  PostRestaurantsGroupAction,
  PutGroupStoreListAction,
  PutGroupGroupListAction,
  PatchGroupAction,
} from './actions'

export * from './actions'
export * from './constants'
export * from './selectors'
export * from './format'
export * from './middlewares'
export * from './components'

// Example of restaurantsGroup data
// {
//   restaurantsGroup: null,
//   isFetching: false,
//   lastUpdated: null,
//   error: null,
// }

const DEFAULT = Immutable.fromJS({
  list: {},
  putStoreList: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    error: null,
  },
  putGroupList: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    error: null,
  },
  customTexts: {
    data: null,
    isFetching: false,
    lastUpdated: null,
    error: null,
  },
})

// force id to be a string, avoid errors while retrieve the data where the id is pass as a int
// immutable objects use keys as string, not int
const getRestaurantsGroupIdOnAction = action => String(action.data.restaurantsGroupId)

const onRequest = (state, action) => {
  const restaurantsGroupId = getRestaurantsGroupIdOnAction(action)

  return state.setIn(
    ['list', restaurantsGroupId],
    Immutable.fromJS({
      restaurantsGroup: null,
      isFetching: true,
      lastUpdated: null,
      error: null,
    })
  )
}

const onSuccess = (state, action) => {
  const restaurantsGroupId = getRestaurantsGroupIdOnAction(action)

  return state.setIn(
    ['list', restaurantsGroupId],
    Immutable.fromJS({
      restaurantsGroup: action.response,
      isFetching: false,
      lastUpdated: new Date(),
      error: null,
    })
  )
}

const onFailure = (state, action) => {
  const restaurantsGroupId = getRestaurantsGroupIdOnAction(action)

  return state.setIn(
    ['list', restaurantsGroupId],
    Immutable.fromJS({
      restaurantsGroup: null,
      isFetching: false,
      lastUpdated: new Date(),
      error: action.error,
    })
  )
}

//
//
//

const onPatchRequest = (state, action) => {
  const restaurantsGroupId = getRestaurantsGroupIdOnAction(action)

  return state.mergeIn(['list', restaurantsGroupId], {
    isFetching: true,
    error: null,
  })
}

const onPatchFailure = (state, action) => {
  const restaurantsGroupId = getRestaurantsGroupIdOnAction(action)

  return state.mergeIn(['list', restaurantsGroupId], {
    isFetching: false,
    lastUpdated: new Date(),
    error: action.error,
  })
}

const onRequestPutGroupStoreList = (state, action) => {
  return state.merge({
    putStoreList: {
      data: null,
      isFetching: true,
      lastUpdated: null,
      error: null,
    },
  })
}

const onSuccessPutGroupStoreList = (state, action) => {
  return state.merge({
    putStoreList: {
      data: action.response,
      isFetching: false,
      lastUpdated: new Date(),
      error: null,
    },
  })
}

const onFailurePutGroupStoreList = (state, action) => {
  return state.merge({
    putStoreList: {
      data: null,
      isFetching: false,
      lastUpdated: new Date(),
      error: action.error,
    },
  })
}

const onRequestPutGroupGroupList = (state, action) => {
  return state.merge({
    putGroupList: {
      data: null,
      isFetching: true,
      lastUpdated: null,
      error: null,
    },
  })
}

const onSuccessPutGroupGroupList = (state, action) => {
  return state.merge({
    putGroupList: {
      data: action.response,
      isFetching: false,
      lastUpdated: new Date(),
      error: null,
    },
  })
}

const onFailurePutGroupGroupList = (state, action) => {
  return state.merge({
    putGroupList: {
      data: null,
      isFetching: false,
      lastUpdated: new Date(),
      error: action.error,
    },
  })
}

export const restaurantsGroupReducer = createReducer(DEFAULT, {
  [GetRestaurantsGroupAction.REQUEST]: onRequest,
  [GetRestaurantsGroupAction.SUCCESS]: onSuccess,
  [GetRestaurantsGroupAction.FAILURE]: onFailure,
  [PutGroupStoreListAction.REQUEST]: onRequestPutGroupStoreList,
  [PutGroupStoreListAction.SUCCESS]: onSuccessPutGroupStoreList,
  [PutGroupStoreListAction.FAILURE]: onFailurePutGroupStoreList,
  [PutGroupGroupListAction.REQUEST]: onRequestPutGroupGroupList,
  [PutGroupGroupListAction.SUCCESS]: onSuccessPutGroupGroupList,
  [PutGroupGroupListAction.FAILURE]: onFailurePutGroupGroupList,
  [PatchGroupAction.REQUEST]: onPatchRequest,
  [PatchGroupAction.FAILURE]: onPatchFailure,
  [PatchGroupAction.SUCCESS]: onSuccess,
})

export const postRestaurantsGroupReducer = createSimpleReducer(PostRestaurantsGroupAction)
