import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { getForCurrentLocale } from 'pmt-modules/i18n'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { createSubObjectFormatter, createListFormatter, createFormatter } from 'pmt-utils/format'
import { CartModifierActionType, CartModifierTargetType } from '../constants'

const removeComplexCartModifier = cartModifiers => {
  const newCartModifiers = (cartModifiers || []).filter(cartModifier => !cartModifier.complex)

  return !isEmpty(newCartModifiers) ? newCartModifiers : null
}

export const formatItemsCartModifiers = items => {
  items = items.map(item => {
    item.cartModifiers.list = removeComplexCartModifier(item.cartModifiers.list)
    item.hasCartModifier = !isNil(item.cartModifiers.list)

    if (item.hasCartModifier) {
      item.cartModifiers.newPriceFormatted = formatPriceWithCurrency(item.cartModifiers.newPrice)
      item.basePrice = item.price
      item.basePriceFormatted = item.priceFormatted
      item.price = item.cartModifiers.newPrice
      item.priceFormatted = item.cartModifiers.newPriceFormatted
    }
    return item
  })

  return items
}

const recursiveFormatSubcategoriesCartModifiers = category => {
  if (!isEmpty(category.categories)) {
    category.categories = category.categories.map(category => {
      return recursiveFormatSubcategoriesCartModifiers(category)
    })
  }

  category.items = formatItemsCartModifiers(category.items)

  return category
}

export const formatCartModifier = createFormatter(recursiveFormatSubcategoriesCartModifiers)

export const formatCartModifiers = createFormatter(
  createSubObjectFormatter('categories', createListFormatter(formatCartModifier))
)

export const formatModifier = createFormatter(modifier => {
  modifier.name = getForCurrentLocale(modifier.localizedNames)
  modifier.description = getForCurrentLocale(modifier.localizedDescriptions)

  modifier.isAutomatic = modifier.automatic
  modifier.isComplex = modifier.condition.itemCondition !== null
  modifier.isTargetTypeTotal = modifier.target.type === CartModifierTargetType.TOTAL

  if (modifier.action.type === CartModifierActionType.DISCOUNT_AMOUNT) {
    modifier.action.valueFormatted = formatPriceWithCurrency(modifier.action.value)
  } else if (modifier.action.type === CartModifierActionType.DISCOUNT_PERCENTAGE) {
    modifier.action.valueFormatted = `${modifier.action.value} %`
  }

  return modifier
})

export const formatModifiers = createFormatter(createListFormatter(formatModifier))
