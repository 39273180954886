import React from 'react'
import clsx from 'clsx'

import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  default: {
    background: theme.pmt.status.white,
    border: `1px solid #ccc!important`,
  },
  inactive: {
    background: theme.pmt.status.inactive,
  },
  info: {
    background: theme.pmt.status.info,
    color: theme.pmt.colors.white,
  },
  valid: {
    background: theme.pmt.status.valid,
  },
  warning: {
    background: theme.pmt.status.warning,
  },
  error: {
    background: theme.pmt.status.error,
    color: theme.pmt.colors.white,
  },
}))

/**
 * This component should not be used as is
 * It only provides Status Type enum
 * see app/components/StatusChip for details
 */
const StatusChip = ({ className, status, ...otherProps }) => {
  const classes = useStyles()

  return <Chip {...otherProps} className={clsx(className, classes[status])} />
}

StatusChip.Status = {
  DEFAULT: 'default',
  INACTIVE: 'inactive',
  INFO: 'info',
  VALID: 'valid',
  WARNING: 'warning',
  ERROR: 'error',
}

export default StatusChip
