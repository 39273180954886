import isEmpty from 'lodash/isEmpty'

import { API_CALL, REQUEST, TYPES } from 'pmt-modules/api/constants'

import {
  RefreshAction,
  refreshAction,
  retryApiCallAction,
  refreshActionDone,
} from './actions'

// refreshing action status
let _isRefreshingToken = false

// stacked actions list to unstack after refreshing action
let _actions = []

export const clearAttemptRefresh = () => {
  _isRefreshingToken = false
  _actions = []
}

// if given action is refresh call action
const defaultIsRefreshCall = action =>
  action[API_CALL][TYPES][0] === RefreshAction.REQUEST

const requestNewAccessToken = ({
  dispatch,
  next,
  refreshAction,
  refreshToken,
  oauthClient,
}) => {
  // we are now refreshing, prevent any other refresh call
  _isRefreshingToken = true

  dispatch(
    refreshAction(refreshToken, oauthClient, {
      //
      // We trick here to pass a callback to be called when the refresh token call has finished
      //
      then: (state, success, response) => {
        // Refresh was successful
        // call postRefreshToken
        if (success) {
          postRefreshToken({
            error: false,
            response,
            actions: _actions,
            dispatch,
          })
        } else {
          postRefreshToken({
            error: true,
            response: null,
            actions: _actions,
            dispatch,
          })
        }
      },
    })
  )
}

/**
 * When the refresh attempt is done, we fire all the actions that have been queued until
 * its completion. If, the refresh promise was unsuccessful we logout the user.
 */
const postRefreshToken = ({ response, error, actions, dispatch }) => {
  if (!error) {
    // no error, run all pending actions
    actions.forEach(action => {
      // We need to update the access token on the request
      if (!isEmpty(action[API_CALL][REQUEST].headers.authorization)) {
        action[API_CALL][REQUEST].headers.authorization = `Bearer ${
          response.access_token
        }`
        // update the request with response for requestConfigurationMiddleware to use
        // the most recent response and get the correct authData.expiresAt value
        action[API_CALL][REQUEST].authData = response
      }
      dispatch(retryApiCallAction(action))
    })
  } else {
    // TODO: dispatch error for every actions
    // + do same for attempt refresh
    actions.forEach(action => {
      dispatch(error)
    })
  }

  clearAttemptRefresh()
  dispatch(refreshActionDone())
}

export const queueActionForRefreshToken = ({
  action,
  dispatch,
  next,
  oauthClient,
  refreshToken,
}) => {
  // current action is not a refresh call action, we stack it
  if (!defaultIsRefreshCall(action)) {
    _actions.push(action)
  }

  // we are not arleady refreshing, do it
  if (!_isRefreshingToken) {
    requestNewAccessToken({
      dispatch,
      next,
      oauthClient,
      refreshAction,
      refreshToken,
    })
  }
}
