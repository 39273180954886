import Immutable from 'immutable'

const CHANGE_CURRENT_THEME = 'Theme::CHANGE_CURRENT_THEME'

export const changeCurrentTheme = (newCurrentTheme) => ({
  type: CHANGE_CURRENT_THEME,
  newCurrentTheme
})

// configure it on the appOptions
// theme: {
//  muiTheme: { } <- on this value
// }
const DEFAULT = Immutable.fromJS({})

//
// current theme reducer
//
export const currentTheme = (state = DEFAULT, action) => {
  switch (action.type) {
    case CHANGE_CURRENT_THEME:
      return Immutable.fromJS(action.newCurrentTheme)

    default:
      return state
  }
}
