import Immutable from 'immutable'
import { createReducer } from '../redux'

import { GetStatisticsAction, StatisticsAction } from './actions'

export * from './actions'
export * from './components'
export * from './config'
export * from './constants'
export * from './utils'
export * from './selectors'
export { default as createStatistics } from './createStatistics'
export { default as createGroupStatistics } from './createGroupStatistics'

export StatisticsGroupType from './StatisticsGroupType'

const checkRequestId = reduceFunc => (state, action) => {
  const requestId = state.getIn([
    action.data.statisticsGroupType,
    action.data.statisticsType,
    'requestId',
  ])

  if (requestId === action.data.requestId) {
    return reduceFunc(state, action)
  }

  console.debug(`Response ignore due to invalid requestId '${action.data.requestId}'`)
  return state
}

let DEFAULT_STATE = Immutable.fromJS({})

export const statisticsReducer = createReducer(DEFAULT_STATE, {
  [StatisticsAction.UPDATE]: (state, action) =>
    state.mergeIn(
      [action.statisticsGroupType],
      Immutable.fromJS({
        options: action.options,
      })
    ),

  [StatisticsAction.INIT_CONFIG]: (state, action) =>
    state.mergeIn(
      [action.statisticsGroupType],
      Immutable.fromJS({
        config: action.config,
      })
    ),

  [GetStatisticsAction.REQUEST]: (state, action) =>
    state
      .mergeIn(
        [action.data.statisticsGroupType],
        Immutable.fromJS({
          options: action.data.props.options,
        })
      )
      .mergeIn([action.data.statisticsGroupType, action.data.statisticsType], {
        data: null,
        isFetching: true,
        error: null,
        //
        // We keep an id of the last request run. We will ignore success / failure responses of old
        // request (who don't have this requestId)
        //
        requestId: action.data.requestId,
      }),

  [GetStatisticsAction.SUCCESS]: checkRequestId((state, action) =>
    state.mergeIn([action.data.statisticsGroupType, action.data.statisticsType], {
      data: action.response,
      isFetching: false,
      error: null,
    })
  ),

  [GetStatisticsAction.FAILURE]: checkRequestId((state, action) =>
    state.mergeIn([action.data.statisticsGroupType, action.data.statisticsType], {
      data: null,
      isFetching: false,
      error: action.error,
    })
  ),
})
