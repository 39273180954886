import { UnauthorizedAction } from '../actions'
import { createMiddleware } from 'pmt-modules/redux'
import { redirectTo, getRoute, getConfigForRoute } from 'pmt-modules/routing'
import { getUserLightCookie } from 'pmt-modules/auth/utils'
import { getAppConfig } from 'pmt-modules/appConfig/selectors'
import { AuthMode } from 'pmt-modules/auth'

/**
 * Handle 401
 */
export const unauthorizedMiddleware = createMiddleware(
  UnauthorizedAction,
  ({ next, dispatch, getState }) => {
    // we redirect to the current page after the login is complete
    const currentUrl = window.location.toString()

    // if we are not already on login page
    if (window.location.pathname !== '/login') {
      const state = getState()
      const authenticationMode = getAppConfig(state).authentication.mode
      if (authenticationMode === AuthMode.NORMAL) {
        if (getUserLightCookie()) {
          // user is light, but should be normal => force Registering

          // if their is a specific page for Registering, use it
          if (getConfigForRoute('REGISTER')) {
            next(redirectTo(getRoute('REGISTER'), null, { redirectTo: currentUrl }))
          } else {
            // use the register form in the login-or-register page
            next(redirectTo(getRoute('LOGIN'), null, { redirectTo: currentUrl }))
          }
        } else {
          next(redirectTo(getRoute('LOGIN'), null, { redirectTo: currentUrl }))
        }
      } else if (authenticationMode === AuthMode.LIGHT) {
        // receive a 401 for example when in test mode and not logged in to acces a testMode restaurant
        dispatch(redirectTo(getRoute('LOGIN'), null, { redirectTo: currentUrl }))
      }
    }
  }
)
