import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'
import each from 'lodash/each'

import { getValidator, getFormOptions } from './config'

const DEFAULT_VALIDATE = {
  isValid: true,
}

const getFormState = state => state.form
const getFormTypeData = (state, props) => getFormState(state).get(props.formType)
const getFormType = (state, props) => props.formType
const getValidatorSelector = (formType, props) => getValidator(props.formType)

export const makeGetFormDataNotFormatted = () =>
  createSelector([getFormTypeData, getFormType], (formState, formType) => {
    if (isNil(formState)) {
      return {}
    }
    let formData = formState.toJS().formData

    if (!formData) {
      formData = {}
    }

    formData.formType = formType

    return formData
  })

export const makeGetFormData = () =>
  createSelector([makeGetFormDataNotFormatted(), getFormType], (formData, formType) => {
    if (isNil(formData)) {
      return {}
    }
    return formData
  })

const getFormsDataArray = (state, props) => {
  if (!props.formTypes) {
    // FIXME: should not happend
    return []
  }

  return props.formTypes.map(formType => ({
    formType,
    ...makeGetFormData()(state, { formType }),
  }))
}

export const makeGetFormsData = () =>
  createSelector([getFormsDataArray], formsData => {
    const formattedFormsData = {}
    formsData.forEach(formData => {
      formattedFormsData[formData.formType] = {
        data: formData.data,
        // TODO: put above ?
        ...formData,
      }
    })

    return formattedFormsData
  })

export const makeGetFormOptions = () =>
  createSelector([getFormTypeData], formTypeData => {
    return formTypeData.toJS().options || {}
  })

export const makeGetFormErrors = () =>
  createSelector(
    [makeGetFormDataNotFormatted(), getValidatorSelector, makeGetFormOptions()],
    (formData, validator, formOptions) => {
      const { formType } = formData

      if (isNil(validator)) {
        return DEFAULT_VALIDATE
      }
      const errors = validator(formData, formOptions)
      // set formType again since validator override it
      errors.formType = formType

      // create invalidFields, an array of the field names that are not valid
      errors.invalidFields = []
      each(errors, (error, fieldName) => {
        if (error.isValid === false) {
          errors.invalidFields.push(fieldName)
        }
      })

      return errors
    }
  )

export const makeIsFormValid = createSelector([makeGetFormErrors()], formErrors => {
  return formErrors.isValid
})

export const makeGetFormsErrors = () =>
  createSelector([getFormsDataArray], formsData => {
    // TODO: move to format
    let isValid = true

    const errors = {}
    formsData.forEach(formData => {
      const { formType } = formData
      const validator = getValidator(formType)
      const formOptions = getFormOptions(formType)

      if (isNil(validator)) {
        errors[formType] = DEFAULT_VALIDATE
      } else {
        const validation = validator(formData, formOptions)
        if (!validation.isValid) {
          isValid = false
        }
        errors[formType] = validation
      }
      // set formType again since validator override it
      errors[formType].formType = formType
    })

    return {
      isValid,
      errors,
    }
  })
