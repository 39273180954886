import sortBy from 'lodash/sortBy'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import each from 'lodash/each'

import { isBo, getApiUrl } from 'pmt-modules/environment'
import { createFormatter, createMapFormatter, createListFormatter } from 'pmt-utils/format'
import { getDistanceFromLatLon } from 'pmt-utils/geoloc'
import { formatDate } from 'pmt-utils/date'
import {
  EPaymentMode,
  getPaymentLabel,
  isAcceptingCreditCardType,
  PspRegisterUserCardType,
} from 'pmt-modules/creditCard/constants'
import { PspType } from 'pmt-modules/restaurant/constants'

import { RestaurantStatus, getRestaurantStatusLabel } from '../constants'

import { formatRestaurantOpeningHours } from './openingHours'
import { formatOrderSettings } from './orderSettings'
import { formatFeatures } from '../../global'
import { formatLegal } from './formatLegal'

const formatAddress = restaurant => {
  if (!restaurant.geoPt) {
    restaurant.geoPt = {
      latitude: 0,
      longitude: 0,
    }
  }

  const address = restaurant.address
  if (!address) {
    restaurant.address = {}
  }
  restaurant.hasAddress = !isEmpty(address?.street)
  if (restaurant.hasAddress) {
    restaurant.formattedAddress = `${address.street}, ${address.postCode} ${address.city}`
  } else {
    restaurant.formattedAddress = ``
  }
  return restaurant
}

const formatDistance = (restaurant, { latitude, longitude }) => {
  restaurant.geoPt.distance = null
  restaurant.geoPt.distanceFormatted = null

  if (!isNil(latitude) && !isNil(longitude)) {
    const distance = getDistanceFromLatLon(
      latitude,
      longitude,
      restaurant.geoPt.latitude,
      restaurant.geoPt.longitude
    )
    restaurant.geoPt.distance = Math.round(distance * 1000)

    if (distance < 1) {
      restaurant.geoPt.distanceFormatted = `${Math.round(distance * 1000)}m`
    } else {
      restaurant.geoPt.distanceFormatted = `${Math.round(distance * 10) / 10}km`
    }
  }

  return restaurant
}

const sortByName = list => sortBy(list, [restaurant => restaurant.name.toLowerCase()], ['desc'])

const formatLogo = restaurant => {
  restaurant.hasLogo = !isEmpty(restaurant.logo)
  return restaurant
}

const formatPaymentMethods = restaurant => {
  const paymentMethodsAllowed = []

  each(EPaymentMode, paymentMode => {
    if (isAcceptingCreditCardType(paymentMode, restaurant.payMode)) {
      paymentMethodsAllowed.push(getPaymentLabel(paymentMode))
    }
  })
  restaurant.paymentMethodsAllowed = paymentMethodsAllowed

  return restaurant
}

const formatPsp = restaurant => {
  restaurant.isPspMangoPay = restaurant.psp === PspType.MANGOPAY
  restaurant.isPspPayZen = restaurant.psp === PspType.PAYZEN
  restaurant.isPspPayGreen = restaurant.psp === PspType.PAYGREEN
  restaurant.isPspSaferpay = restaurant.psp === PspType.SAFERPAY

  return restaurant
}

const formatData = restaurant => {
  restaurant.isStatusEnabled = restaurant.status === RestaurantStatus.ENABLED
  restaurant.isStatusDisabled = restaurant.status === RestaurantStatus.DISABLED
  restaurant.isStatusCreated = restaurant.status === RestaurantStatus.CREATED
  restaurant.isStatusArchived = restaurant.status === RestaurantStatus.ARCHIVED

  restaurant.hasPaymentWithCardPrint =
    restaurant.pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES

  if (isBo()) {
    restaurant.hasExternalId =
      !isNil(restaurant.externalId) &&
      !isEmpty(restaurant.externalId) &&
      // handle bug with populate data script that send "null" as a string instead of null
      restaurant.externalId !== 'null'
    restaurant.statusLabel = getRestaurantStatusLabel(restaurant.status)

    restaurant.modificationDateFormatted = formatDate(
      restaurant.modificationDate,
      'DD/MM/YYYY HH:mm:ss'
    )

    restaurant.restaurantMenuLink = `${getApiUrl()}/restaurants/${
      restaurant.id
    }/menu?stocksSource=LOCAL_ONLY&menuSource=LOCAL_ONLY`
  }

  restaurant.hasTheme = !isNil(restaurant.theme)
  return restaurant
}

export const formatRestaurant = createFormatter(
  formatRestaurantOpeningHours, // must be after `formatRestaurantOpeningHoursList`
  formatData,
  formatFeatures,
  formatAddress,
  formatDistance,
  formatOrderSettings,
  formatLogo,
  formatPsp,
  restaurant => {
    restaurant.legal = formatLegal(restaurant.legal, { restaurant })
    return restaurant
  },
  formatPaymentMethods
)

export const formatRestaurantsMap = createMapFormatter(formatRestaurant)

export const formatRestaurantList = createListFormatter(formatRestaurant)

export const formatSearchRestaurantsList = createFormatter(
  formatRestaurantList,
  sortByName,
  list => {
    return {
      list,
      counters: {
        statusEnabled: list.filter(r => r.isStatusEnabled).length,
        statusDisabled: list.filter(r => r.isStatusDisabled).length,
        statusCreated: list.filter(r => r.isStatusCreated).length,
        statusArchived: list.filter(r => r.isStatusArchived).length,
      },
    }
  }
)

/* spec: https://www.notion.so/paymytable/Am-liorer-l-affichage-des-r-sultats-du-store-locator-2669fffd951f483280655bcbb2124fc6# */
export const formatRestaurantsListData = createFormatter(data => {
  const Type = {
    /**
     * No geo pt data were found.
     */
    NO_GEO_PT: 'NO_GEO_PT',

    /**
     * Use the latitude/longitude infer from the ip address.
     */
    USE_IP: 'USE_IP',

    /**
     * Use the latitude/longitude of the specified restaurant
     */
    RESTAURANT_GEO_PT: 'RESTAURANT_GEO_PT',

    /**
     * Use the latitude/longitude given by the front
     */
    GIVEN_GEO_PT: 'GIVEN_GEO_PT',
  }

  data.isTypeNoGeoPt = data.type === Type.NO_GEO_PT
  data.isTypeUseIp = data.type === Type.USE_IP
  data.isTypeRestaurantGeoPt = data.type === Type.RESTAURANT_GEO_PT
  data.isTypeGivenGeoPt = data.type === Type.GIVEN_GEO_PT

  data.geoPtIsLowAccuracy = data.isTypeNoGeoPt || data.isTypeUseIp
  data.geoPtUsed = data.isTypeUseIp || data.isTypeRestaurantGeoPt || data.isTypeGivenGeoPt
  return data
})
