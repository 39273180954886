import { __DEV__ } from '../environment'
import invariant from 'invariant'
import find from 'lodash/find'

import createMiddleware from '../redux/createMiddleware'

import { postCatchedData } from './actions'

import { getDataCatcherSettings } from './config'

const shouldCatchData = action =>
  find(getDataCatcherSettings(), setting => setting.actionType === action.type) !== undefined

export const dataCatcherMiddleware = createMiddleware(
  shouldCatchData,
  ({ getState, dispatch, next, action }) => {
    getDataCatcherSettings().forEach(setting => {
      let { actionType, getObject, dataCatcherType } = setting
      if (actionType === action.type) {
        if (__DEV__) {
          invariant(
            typeof getObject === 'function',
            `getObject for actionType ${actionType} is not a function`
          )
        }
        dispatch(postCatchedData(dataCatcherType, getObject(action)))
      }
    })

    return next(action)
  }
)

export const dataCatcherMiddlewares = [dataCatcherMiddleware]
