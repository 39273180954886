import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import Immutable from 'immutable'
import { createSelector } from 'reselect'

import { getCurrentLanguageWithoutRegionCode } from 'pmt-modules/i18n'

import Logger from 'pmt-utils/logger'

import { formatTexts } from './format/texts'
import { getDefaultAppConfig } from './config'
import { mergeConfigObject } from './utils'
import { formatAppConfig, formatOrderAppConfig, formatAppConfigPreset } from './format'

const getState = state => state.entities.appConfig

// sometimes there is a weird state where the app config data is not immutable
// we avoid crashes at the moment by forcing immutability
const getAppConfigState = createSelector([getState], appConfigState => {
  if (isNil(appConfigState)) {
    return null
  }

  if (!isFunction(appConfigState.toJS)) {
    console.log('state is not immutable')
    return Immutable.fromJS(appConfigState)
  }

  return appConfigState
})

export const isFetchingAppConfig = createSelector([getAppConfigState], appConfigState => {
  if (isNil(appConfigState)) {
    return false
  }

  return appConfigState.get('isFetching', false)
})

export const getAppConfigError = createSelector([getAppConfigState], appConfigState => {
  if (isNil(appConfigState)) {
    return null
  }

  const error = appConfigState.get('error', null)
  return isNil(error) ? null : error.toJS()
})

export const getAppConfigData = createSelector([getAppConfigState], appConfigState => {
  if (isNil(appConfigState)) {
    return null
  }

  const appConfigData = appConfigState.get('data', null)
  if (isNil(appConfigData)) {
    return null
  }

  // sometimes sentry is fired because toJS is not a function
  // add some logs to see where this comes from
  if (!isFunction(appConfigData.toJS)) {
    Logger.warn('AppConfig', 'data is not immutable', {
      appConfigState,
      appConfigData,
    })
    return Immutable.fromJS(appConfigData)
  }

  return appConfigData
})

export const getAppConfig = createSelector(
  [getAppConfigData],
  appConfigData => (isNil(appConfigData) ? null : formatAppConfig(appConfigData.toJS()))
)

export const getOauthClient = createSelector([getAppConfigData], appConfigData => {
  if (isNil(appConfigData)) {
    return null
  }

  const auth = appConfigData.get('authentication', null)
  if (!isNil(auth)) {
    const oauth = auth.get('oauth2', null)

    return !isNil(oauth) ? oauth.toJS() : null
  }

  return null
})

export const getMergedConfig = (data, type) => {
  if (isNil(data)) {
    return getDefaultAppConfig()[type]
  }

  let typeConfig = data.get(type, null)
  if (!isNil(typeConfig)) {
    typeConfig = typeConfig.toJS()
  }

  return mergeConfigObject(getDefaultAppConfig()[type], typeConfig)
}

export const getAppTexts = createSelector(
  [getAppConfigData, getCurrentLanguageWithoutRegionCode],
  (appConfigData, locale) => formatTexts(getMergedConfig(appConfigData, 'texts'), locale)
)

/**
 * returns user settings of the current app config
 */
export const getUserSettings = createSelector([getAppConfigData], appConfigData =>
  getMergedConfig(appConfigData, 'user')
)

/**
 * returns front settings of the current app config
 */
export const getFrontSettings = createSelector([getAppConfigData], appConfigData =>
  getMergedConfig(appConfigData, 'front')
)

/**
 * returns order settings of the current app config
 */
export const getOrderSettings = createSelector([getAppConfigData], appConfigData =>
  formatOrderAppConfig(getMergedConfig(appConfigData, 'order'))
)

/**
 * returns security settings of the current app config
 */
export const getSecuritySettings = createSelector([getAppConfigData], appConfigData =>
  getMergedConfig(appConfigData, 'security')
)

export const getAppConfigPreset = createSelector([getAppConfigState], appConfigState => {
  if (isNil(appConfigState)) {
    return null
  }

  return formatAppConfigPreset({ preset: appConfigState.get('preset', null) })
})
