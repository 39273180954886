export const Events = {
  ON_APP_INIT: 'EVENT::GLOBAL::APP_INIT',
  ON_ANALYTICS_INIT: 'EVENT::GLOBAL::ANALYTICS::INIT',
  ON_HISTORY_CHANGE: 'EVENT::GLOBAL::HISTORY_CHANGE',

  ON_RESTAURANT_LIST_SUCCESS: 'EVENT::GLOBAL::RESTAURANT_LIST::SUCCESS',
  ON_LOGIN_SUCCESS: 'EVENT::GLOBAL::LOGIN::SUCCESS',
  ON_REGISTER_SUCCESS: 'EVENT::GLOBAL::REGISTER::SUCCESS',
  ON_PAYMENT_SUCCESS: 'EVENT::GLOBAL::PAYMENT::SUCCESS',
}

export default Events
