import { createFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { getCountryNameForCode } from 'pmt-utils/country'
import { formatPspShopUrl } from 'pmt-modules/psp/format'

const formatLegalRepresentative = legal => {
  const representative = legal.representative

  legal.hasRepresentative = !isNil(representative)

  if (representative) {
    const address = representative.address
    representative.hasAddress =
      !isEmpty(address) &&
      (!isEmpty(address.street) || !isEmpty(address.postCode) || !isEmpty(address.city))
    if (representative.hasAddress) {
      representative.formattedAddress = `${address.street}, ${address.postCode} ${address.city}`
    } else {
      representative.formattedAddress = ''
    }

    representative.hasCountryOfResidence = !isEmpty(representative.countryOfResidence)
    representative.countryOfResidenceLabel =
      getCountryNameForCode(representative.countryOfResidence) || representative.countryOfResidence

    representative.hasNationality = !isEmpty(representative.nationality)
    representative.nationalityLabel =
      getCountryNameForCode(representative.nationality) || representative.nationality
  }

  return legal
}

const formatBank = (bank, props) => {
  bank.pspShopUrl = formatPspShopUrl(props.restaurant.psp, bank.pspRestaurantId)

  return bank
}

export const formatLegal = createFormatter(
  formatLegalRepresentative,
  createSubObjectFormatter('bank', formatBank)
)
