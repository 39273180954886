import React from 'react'

import Snackbar from 'pmt-ui/Snackbar'

class SnackbarError extends React.Component {
  state = {
    open: true,
  }

  handleRequestClose = () => {
    this.setState({ open: false })
    if (this.props.onClose) {
      this.props.onClose()
    }
  }

  render() {
    const { errorMessage } = this.props

    return (
      <Snackbar
        open={this.state.open}
        onClose={this.handleRequestClose}
        message={<span id="message-id">{errorMessage}</span>}
      />
    )
  }
}

export default SnackbarError
