import Immutable from 'immutable'
import invariant from 'invariant'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isUndefined from 'lodash/isUndefined'

/**
 * Generate a simple reducer with basic REQUEST / SUCCESS / FAILURE
 * @param  {string} actionType  Action to be reduced
 * @return {Immutable}          Updated state
 */

const DEFAULT_STATE = Immutable.fromJS({
  data: null,
  isFetching: false,
  error: null,
})

const createSimpleReducer = (actionType, reducer = null) => (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionType.REQUEST:
      return state
        .merge({
          actionData: action.data,
          isFetching: true,
          error: null,
        })
        .merge(
          action?.data?.resetDataOnRequest ?? true
            ? {
                data: null,
              }
            : {}
        )

    case actionType.SUCCESS:
      return state.merge({
        data: action.response,
        isFetching: false,
        error: null,
      })

    case actionType.FAILURE:
      return state.merge({
        data: null,
        isFetching: false,
        error: action.error,
      })

    default:
      if (reducer !== null) {
        invariant(
          isFunction(reducer) || isObject(reducer),
          'reducer must be a function or an object'
        )
        let res
        if (isFunction(reducer)) {
          res = reducer(state, action)
        } else {
          const func = reducer[action.type]
          if (!func) {
            // no reducer function found for the action type.
            return state
          }
          res = func(state, action)
        }
        invariant(!isUndefined(res), 'reducer returned undefined')
        return res
      }
      return state
  }
}

createSimpleReducer.DEFAULT_STATE = DEFAULT_STATE

export default createSimpleReducer
