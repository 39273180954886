import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'
import map from 'lodash/map'

/**
 * Defines the different possible type to display the history (on the web customer)
 */
export const FrontCateringHistoryType = {
  PAY_MY_TABLE: 'PAY_MY_TABLE',
  THIRD_PARTY: 'THIRD_PARTY',
}

export const GroupType = {
  USER: 'USER',
  OTHER: 'OTHER',
}

export const AcquirerType = {
  MANGOPAY: 'MANGOPAY',
  PAYGREEN: 'PAYGREEN',
  FAKE: 'FAKE',
  NONE: null,
}

export const getAcquirerLabel = acquirer => {
  switch (acquirer) {
    case AcquirerType.MANGOPAY:
      return 'MangoPay'
    case AcquirerType.PAYGREEN:
      return 'PayGreen'
    case AcquirerType.FAKE:
      return 'FAKE'
    default:
      return tr('global.none')
  }
}

export const UserManagerType = {
  NONE: null,
  LDB: 'leon_de_bruxelles',
}

export const getUserManagerTypeLabel = type => {
  switch (type) {
    case UserManagerType.LDB:
      return 'Leon de Bruxelles'
    default:
      return tr('global.none')
  }
}

export const getGroupType = type => GroupType[type]

export const getGroupTypeLabel = type => {
  switch (type) {
    case GroupType.USER:
      return tr('global.groups.type.user')
    case GroupType.OTHER:
      return tr('global.groups.type.other')
    default:
      return ''
  }
}

export const getGroupTypesLabelAsArray = () =>
  keys(GroupType).map(value => ({
    label: getGroupTypeLabel(value),
    value,
  }))

export const UserAccountManagerType = {
  NONE: 'NONE',

  /**
   * Local user account manager
   */
  paymytable: 'paymytable',

  /**
   * Cashpad user account manager
   */
  cashpad: 'cashpad',

  cashpadV2: 'cashpadV2',

  /**
   * POS CSI user account manager. User account manager settings identifier must contain
   * exploitation code + ":" + exploitation key (both values provided by CSI for each site)
   */
  csi: 'csi',
}

/**
 * Enum of locales that are handled by PMT. Only those locales can be defined as used locales on our
 * configurations (such as RestaurantsGroup locales).
 */
export const PMTLocale = {
  fr_FR: 'fr-FR',

  fr_CH: 'fr-CH',

  en_US: 'en-US',

  de_DE: 'de-DE',
}

export const getLocales = () => map(PMTLocale, value => value)

export const PaymentMethodDetail = {
  //
  // GENERIC/DEFAULT VALUES
  //

  /**
   * Default value to be use.
   * <p>
   * In general means that the payment service is still unknown (eg we didn't receive the payment infos yet)
   */
  UNKNOWN: 'UNKNOWN',
  /**
   * For payment method not supported.
   * <p>
   * In general means that the payment service is not known by PMT
   */
  OTHER: 'OTHER',

  //
  // CARDS
  //

  /**
   * Generic card (eg if PSP doesn't support card brand separation like Mangopay)
   */
  CARD: 'CARD',
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  /**
   * MasterCard's Debit only cards
   */
  MAESTRO: 'MAESTRO',
  /**
   * American Express
   */
  AMEX: 'AMEX',
  /**
   * <a href="https://en.wikipedia.org/wiki/Diners_Club_International">Diners Club International</a>
   * AND <a href="https://en.wikipedia.org/wiki/Discover_Card">Discover Card</a>.
   * <p>
   * Note: Those are linked because Saferpay keep them linked.
   */
  DINERS: 'DINERS',
  /**
   * PostFinance Card
   * <p>
   * Note: To see in the future but can be linked with {@link PaymentMethodDetailEnum#POSTFINANCE}
   */
  POSTCARD: 'POSTCARD',
  /**
   * <a href="https://en.wikipedia.org/wiki/JCB_Co.,_Ltd">Japan Credit Bureau</a>.
   */
  JCB: 'JCB',
  /**
   * MyOne is a local Swiss payment card
   */
  MYONE: 'MYONE',
  /**
   * <a href="https://en.wikipedia.org/wiki/UnionPay">UnionPay</a>, China
   */
  UNIONPAY: 'UNIONPAY',

  //
  // ONLINE PAYMENT SERVICES
  //

  PAYPAL: 'PAYPAL',
  /**
   * <a href="https://www.twint.ch/en/">TWINT</a>,Switzerland’s payment app (also a bit present in Germany and France)
   */
  TWINT: 'TWINT',
  /**
   * <a href="https://en.wikipedia.org/wiki/Klarna">Klarna</a> |
   * <a href="https://docs.saferpay.com/home/integration-guide/payment-methods/klarna-payments">Klarna Payments</a>
   */
  KLARNA: 'KLARNA',
  /**
   * <a href="https://en.wikipedia.org/wiki/Alipay">Alipay</a>
   */
  ALIPAY: 'ALIPAY',
  /**
   * <a href="https://fr.wikipedia.org/wiki/Bancontact">Bancontact</a>, Belgium
   */
  BANCONTACT: 'BANCONTACT',
  /**
   * <a href="https://docs.connect.worldline-solutions.com/payment-product/ePrzelewy/overview">ePrzelewy</a>, Poland
   */
  EPRZELEWY: 'EPRZELEWY',
  /**
   * <a href="https://en.wikipedia.org/wiki/Giropay">Giropay</a>, Germany
   */
  GIROPAY: 'GIROPAY',
  /**
   * <a href="https://en.wikipedia.org/wiki/IDEAL">IDEAL</a>, Netherland
   */
  IDEAL: 'IDEAL',
  /**
   * PostFinance eFinance
   * <p>
   * Note: To see in the future but can be linked with {@link PaymentMethodDetailEnum#POSTCARD}
   */
  POSTFINANCE: 'POSTFINANCE',

  //
  // OTHER
  //

  TRD: 'TRD',
  /**
   * SEPA
   */
  DIRECTDEBIT: 'DIRECTDEBIT',
  /**
   * <a href="https://stripe.com/fr/legal/eps">Electronic Payment Standard</a>, Austria
   */
  EPS: 'EPS',
}

export const getPaymentMethodDetailLabel = paymentMethodDetail => {
  switch (paymentMethodDetail) {
    case PaymentMethodDetail.TRD:
      return tr('global.payment.method.detail.TRD')
    case PaymentMethodDetail.CARD:
      return tr('global.payment.method.detail.CARD')
    case PaymentMethodDetail.OTHER:
      return tr('global.payment.method.detail.OTHER')
    case PaymentMethodDetail.UNKNOWN:
      return tr('global.payment.method.detail.UNKNOWN')
    default:
      return paymentMethodDetail
  }
}
