import { createMiddleware } from '../../redux'

import { isDev } from 'pmt-modules/environment'

import { PutFeedbackAnswer, FetchFeedbackAnswer } from './actions'
import { FeedbackAnswerStatus } from './constants'
import { redirectTo, getRoute } from 'pmt-modules/routing'

// an updated feedback is answered, so we redirect to the confirm page.
const putAnswerFeedbackSuccessMiddleware = createMiddleware(
  PutFeedbackAnswer.SUCCESS,
  ({ dispatch, action }) => {
    dispatch(
      redirectTo(getRoute('GLOBAL_FEEDBACK_CONFIRM'), {
        feedbackAnswerToken: action.response.token,
      })
    )
  }
)

// redirect to the confirm page if the feedback is already answered
const globalFetchFeedbackSuccessMiddleware = createMiddleware(
  FetchFeedbackAnswer.SUCCESS,
  ({ dispatch, action }) => {
    // in dev we can answer to an already ANSWERED feedback to simplify the dev
    if (action.response.status === FeedbackAnswerStatus.ANSWERED && !isDev()) {
      dispatch(
        redirectTo(getRoute('GLOBAL_FEEDBACK_CONFIRM'), {
          feedbackAnswerToken: action.response.token,
        })
      )
    }
  }
)

// redirect to 404 if we can't retrieve the feedback answer
const globalFetchFeedbackAnswerFailureMiddleware = createMiddleware(
  FetchFeedbackAnswer.FAILURE,
  ({ dispatch }) => {
    dispatch(redirectTo(getRoute('PAGE_NOT_FOUND')))
  }
)

export const globalFeedbackMiddlewares = [
  putAnswerFeedbackSuccessMiddleware,
  globalFetchFeedbackSuccessMiddleware,
  globalFetchFeedbackAnswerFailureMiddleware,
]
