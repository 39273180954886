import find from 'lodash/find'
import forEach from 'lodash/forEach'

export const localeEquals = (localeA = '', localeB = '') => {
  // trick to handle compare 'fr-FR' with 'fr' or 'FR'
  if (localeA?.length !== localeB?.length) {
    localeA = localeA?.substring(0, 2)
    localeB = localeB?.substring(0, 2)
  }

  return localeA?.replace('-', '_').toLowerCase() === localeB?.replace('-', '_').toLowerCase()
}

export const getForLocale = (localesData, locale) => {
  return find(localesData, localeData => localeEquals(localeData.locale, locale))
}

/**
 *
 * @param {object} localeObject  { 'fr': 'test' }
 * @param {string} localeToSearchFor
 */
export const getForLocaleObject = (localeObject, localeToSearchFor) => {
  let res = null
  forEach(localeObject, (value, locale) => {
    if (localeEquals(locale, localeToSearchFor)) {
      res = value
      return false
    }
  })
  return res
}
