import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import { getFormatter } from './config'

const getSearchState = (state) => state.search
const getSearchTypeData = (state, props) => getSearchState(state).get(props.searchType)
const getSearchType = (state, props) => props.searchType

export const makeGetSearchList = () => createSelector(
  [ getSearchTypeData, getSearchType ],
  (searchData, searchType) => {
    if (isNil(searchData) || isNil(searchData.get('list'))) {
      return []
    }

    const list = searchData.get('list').toJS()

    const formatter = getFormatter(searchType)

    if (formatter) {
      return formatter(list)
    }

    return list
  }
)

export const makeIsFetchingSearchList = () => createSelector(
  [ getSearchTypeData ],
  (searchData) => {
    if (isNil(searchData) || isNil(searchData.get('isFetching'))) {
      return false
    }

    return searchData.get('isFetching')
  }
)

export const makeGetSearchError = () => createSelector(
  [ getSearchTypeData ],
  (searchData) => {
    if (isNil(searchData) || isNil(searchData.get('error'))) {
      return false
    }

    return searchData.get('error').toJS()
  }
)
