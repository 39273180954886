import React from 'react'
import PropTypes from 'prop-types'

import { withTheme } from 'pmt-ui/styles'

/**
 * The CssThemeProvider is a container that provides the current theme on context (`pmtTheme`), and
 * add custom css classes using the theme. (colorPrimary, etc).
 * see https://github.com/sm-react/react-theme-provider
 */
class CssThemeProvider extends React.Component {
  constructor(props, ...args) {
    super(props, ...args)

    this.setCSS = props.setCSS || setCSS
    this.CSSLink = props.CSSLink || CSSLink
    this.className = props.className || 'theme'
  }

  shouldComponentUpdate(nextProps) {
    // return true
    return this.props.theme !== nextProps.theme
  }

  render() {
    const CSSstyles = this.setCSS(this.props.theme, this.className)

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = 'data:text/css;charset=UTF-8,' + encodeURIComponent(CSSstyles)

    // Append to `<head>`
    document.getElementsByTagName('head')[0].appendChild(link)

    return null
  }
}

CssThemeProvider.propTypes = {
  setCSS: PropTypes.func,
  CSSLink: PropTypes.func,
  themeInd: PropTypes.number,
  override: PropTypes.bool,
  className: PropTypes.string,
}

CssThemeProvider.defaultProps = {
  themeInd: 0,
}

function setCSS(theme, className) {
  if (!theme.palette) {
    return null
  }

  const style = `
    .textColor {
      color: ${theme.palette.text.primary} !important;
    }

    .secondaryTextColor {
      color: ${theme.palette.secondary.main} !important;
    }

    .${className}::selection {
      background: ${theme.palette.secondary.main} !important;
    }

    .linkPrimary {
      color: ${theme.palette.primary.main} !important;
    }

    .colorPrimary {
      color: ${theme.palette.primary.main} !important;
    }

    .colorError {
      color: ${theme.pmt.status.error} !important;
    }

    .backgroundPrimary {
      background: ${theme.palette.primary.main} !important;
    }

    .hoverPrimary:hover {
      color: ${theme.palette.primary.main} !important;
    }

    .visitedPrimary:visited {
      color: ${theme.palette.primary.main} !important;
    }

    .borderPrimary {
      border-color: ${theme.palette.primary.main} !important
    }
  `
  return style
}

function CSSLink(CSSdata) {
  return <link rel="stylesheet" type="text/css" href={`data:text/css,${CSSdata}`} />
}

export default withTheme(CssThemeProvider)
