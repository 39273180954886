const OrderStatus = {
  CREATED: 'CREATED',
  SENT: 'SENT',
  RECEIVED: 'RECEIVED',
  PRODUCING: 'PRODUCING',
  READY: 'READY',
  IN_DELIVERY: 'IN_DELIVERY',
  DELIVERED: 'DELIVERED',
  CANCELED: 'CANCELED',
  FAILED: 'FAILED',
}

export default OrderStatus
