import Immutable from 'immutable'
import { createSimpleReducer, createPaginateReducer } from '../../redux'

// /!\ this module is used to answer a feedback. To see feedbacks, use feedbackREsume module

import {
  FetchFeedbackAnswer,
  GetFeedbackAnswersAction,
  GetFeedbackAnswerUserAction,
  PutFeedbackAnswer,
  UpdateFeedbackAnswerFormAction,
} from './actions'
import { combineReducers } from 'redux'

export * from './middlewares'

// contains the retrieved feedback and its updated version by the user
const fetchFeedbackAnswerReducer = createSimpleReducer(FetchFeedbackAnswer, (state, action) => {
  switch (action.type) {
    case UpdateFeedbackAnswerFormAction:
      return state.set('data', Immutable.fromJS(action.feedbackAnswer))

    default:
      return state
  }
})

const putFeedbackAnswerReducer = createSimpleReducer(PutFeedbackAnswer)

const feedbackAnswerUserReducer = createPaginateReducer(GetFeedbackAnswerUserAction)

const feedbackAnswersReducer = createPaginateReducer(GetFeedbackAnswersAction)

export const feedbackAnswerReducer = combineReducers({
  fetch: fetchFeedbackAnswerReducer,
  put: putFeedbackAnswerReducer,
  user: feedbackAnswerUserReducer,
  list: feedbackAnswersReducer,
})
