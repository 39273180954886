import { formatRestaurantOpeningHours } from './openingHours'

/**
 * Require `formatOpeningHoursList`
 */
export const formatOrderSettings = restaurant => {
  if (restaurant.orderSettings) {
    restaurant.orderSettings = formatRestaurantOpeningHours(restaurant.orderSettings)

    // in minutes
    restaurant.orderSettings.manufactureDelayInMinutes =
      restaurant.orderSettings.manufactureDelay / 1000 / 60

    // in minutes
    restaurant.orderSettings.deliverySettings.deliveryTimeInMinutes =
      restaurant.orderSettings.deliverySettings.deliveryTimeSettings.fixTimeSettings.time /
      1000 /
      60
  }

  return restaurant
}
