import { tr } from 'pmt-modules/i18n'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import moment from 'moment'

import { formatModifiers } from 'pmt-modules/catalog/format/cartModifiers'
import { isBo } from 'pmt-modules/environment'
import { formatOrderFees } from 'pmt-modules/fees'
import { formatCheck } from 'pmt-modules/payment/check/format'
import { formatPspTransactionUrl } from 'pmt-modules/psp/format'
import { formatRestaurant } from 'pmt-modules/restaurant/format'
import { ThirdParty } from 'pmt-modules/thirdParty/constants'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { DATE_FORMAT_MS, formatDate, isToday } from 'pmt-utils/date'
import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { getGoogleMapsUrl } from 'pmt-utils/google'

import {
  OrderMode,
  OrderPaymentStatus,
  getCancellingPartyFormatted,
  getOrderModeLabel,
  getOrderPaymentStatusLabel,
  getOrderPaymentStatusType,
  getOrderStatusLabel,
  getOrderStatusType,
} from '../constants'
import OrderStatus from '../constants/OrderStatus'

import { formatAdmissionFees } from './formatAdmissionFees'
import { formatUserAccountPayment } from './formatUserAccountPayment'

const formatThirdParty = order => {
  order.isFromThirdParty = order.thirdPartyIdentifier !== ThirdParty.LEGACY_PMT
  order.isThirdPartyDeliveroo = order.thirdPartyIdentifier === ThirdParty.DELIVEROO
  return order
}

const formatOrderNumber = order => {
  order.orderId = order.posDisplayId
  
  if (!isEmpty(order.posDisplayId) && order.posDisplayId.length >=3 ){
    // keep only the last 3 digits 
    // (because it must be easily memorizable by the user, and it must not cause any issue when printing the ticket)
    // for TCPOS: this was previously done by the API, but now we want the full number in the OrderPaidEmail 
    // so we store the full number and do the truncation only when needed - aka here
    order.truncOrderId = order.posDisplayId.substr(order.posDisplayId.length - 3)
  } else {
    order.truncOrderId = order.posDisplayId || order.posCheckNumber || 'N/A' // N/A when order failed
  }

  // /!\ This was an atempt with bo v2. After rebase we use the truncOrderId above, defined for the
  // web order front.
  // let orderNumber = null
  // const posCheckNumber = get(order, 'check.posCheckNumber', null)
  // if (!isEmpty(posCheckNumber)) {
  //   orderNumber = posCheckNumber.substr(posCheckNumber.length - 3)
  // } else if (!isNil(order.id)) {
  //   const orderIdAsString = order.id.toString()
  //   orderNumber = orderIdAsString.substr(orderIdAsString.length - 3)
  // }

  // TODO: refactor orderNumber to posDisplayId
  order.orderNumber = order.posDisplayId

  order.hasOrderNumber = order.truncOrderId !== null

  return order
}

const formatDates = order => {
  order.formattedDueDate = formatDate(order.dueDate, 'DD/MM/YY HH:mm')

  // adding a more friendly format of formatted due date
  // like "Mercredi 5 février à 11:00"
  let dayText = formatDate(order.dueDate, 'dddd')
  dayText = dayText.charAt(0).toUpperCase() + dayText.slice(1)
  order.formattedDueDateTotal = tr('global.date_at', {
    date: `${dayText} ${formatDate(order.dueDate, 'D MMMM')}`,
    hour: formatDate(order.dueDate, 'HH:mm'),
  })

  const dueDate = moment(order.dueDate)

  order.dueDateFormatted = null
  const date = isToday(dueDate) ? tr('global.today') : dueDate.format('dddd D MMM')

  order.dueDateFormatted = tr(`order.slots.dueDate.${order.mode}`, {
    date,
    dateCapitalized: capitalize(date),
    time: dueDate.format('HH:mm'),
  })

  if (order.creationDate) {
    order.creationDateFormatted = formatDate(order.creationDate, DATE_FORMAT_MS)
  }

  if (order.modificationDate) {
    order.modificationDateFormatted = formatDate(order.modificationDate, DATE_FORMAT_MS)
  }

  if (order.limitRefundDate) {
    order.limitRefundDateFormatted = formatDate(order.limitRefundDate)
  }

  return order
}

const formatOrderMode = order => {
  order.orderModeLabel = getOrderModeLabel(order.mode)
  order.isDeliveryMode = order.mode === OrderMode.DELIVERY
  order.isOnSiteMode = order.mode === OrderMode.ON_SITE
  order.isTakeAwayMode = order.mode === OrderMode.TAKE_AWAY
  return order
}

const formatStatus = order => {
  if (isBo()) {
    order.statusFormatted = getOrderStatusLabel(order.status)
    order.statusType = getOrderStatusType(order.status)
  }

  order.isStatusCreated = order.status === OrderStatus.CREATED
  order.isStatusSent = order.status === OrderStatus.SENT
  order.isStatusReceived = order.status === OrderStatus.RECEIVED
  order.isStatusProducing = order.status === OrderStatus.PRODUCING
  order.isStatusReady = order.status === OrderStatus.READY
  order.isStatusInDelivery = order.status === OrderStatus.IN_DELIVERY
  order.isStatusDelivered = order.status === OrderStatus.DELIVERED
  order.isStatusCanceled = order.status === OrderStatus.CANCELED
  order.isStatusFailed = order.status === OrderStatus.FAILED

  if (order.isStatusCanceled) {
    order.cancellingPartyFormatted = getCancellingPartyFormatted(order.cancellingParty)
  }

  order.isFlowOnGoing =
    !order.isStatusCanceled &&
    !order.isStatusFailed &&
    !order.isStatusDelivered &&
    !order.isStatusCreated

  order.isFlowFinished = !order.isFlowOnGoing
  return order
}

const formatOrderRestaurant = (order, props) => {
  if (props.formattedRestaurantsMap) {
    const restaurant = props.formattedRestaurantsMap[order.restaurantId]
    if (restaurant) {
      order.restaurant = formatRestaurant(restaurant)
    }
  }
  return order
}

const formatPrices = order => {
  order.totalPriceFormatted = formatPriceWithCurrency(order.totalPrice, order.currency)
  order.tipsFormatted = formatPriceWithCurrency(order.tips, order.currency)
  order.cartTotalPriceFormatted = formatPriceWithCurrency(order.cartTotalPrice, order.currency)

  const ON_SITE_PAYMENT_ID = 0 // TODO: still used?

  const { userAccountPayment, irlPayment } = order

  order.hasIrlPayment = !isNil(irlPayment)
  order.hasUserAccountPayment = !isNil(userAccountPayment)
  order.hasThirdPartyPayment = order.isFromThirdParty
  order.hasPayment =
    order.paymentId &&
    order.paymentId !== ON_SITE_PAYMENT_ID &&
    order.check && // when retrieving order on a list, we do not have the check loaded.
    order.check.outstandingBalance !== order.check.total
  order.isOnSitePayment =
    !order.hasIrlPayment &&
    !order.hasUserAccountPayment &&
    !order.paymentId &&
    (!order.check || order.check.outstandingBalance === order.check.total)

  // TODO add payment when API will send it (order.payment !== null ? order.payment.amount : 0)
  order.totalPaidPrice = 0
  if (order.hasIrlPayment) {
    // /!\ order.payment is null in this case
    order.totalPaidPrice += irlPayment.amount
    irlPayment.amountFormatted = formatPriceWithCurrency(irlPayment.amount, order.currency)
    irlPayment.totalWithTipFormatted = formatPriceWithCurrency(irlPayment.amount) // no tips on irlPayment for now
  }
  if (order.hasUserAccountPayment) {
    order.totalPaidPrice +=
      userAccountPayment.amountFromAccount + get(userAccountPayment, 'subsidies.grantAmount', 0)
    userAccountPayment.amountFromAccountFormatted = formatPriceWithCurrency(
      userAccountPayment.amountFromAccount,
      order.currency
    )
  }

  if (order.hasPayment) {
    order.totalPaidPrice += (order.payment.amount + order.payment.tips)
  }

  if (order.hasThirdPartyPayment) {
    // TODO: reduce? for now we only have one payment.
    // empty only on test payments
    order.totalPaidPrice = !isEmpty(order.payments) ? order.payments[0].amount : 0
  }

  order.totalPaidPriceFormatted = formatPriceWithCurrency(order.totalPaidPrice, order.currency)

  order.fees = formatOrderFees(order.fees)

  // use only outstandingBalance to verify payment status
  // see https://www.notion.so/paymytable/Am-liorer-le-label-Rester-payer-sur-la-vue-d-une-commande-d-un-client-780ca5bbe89040c1be821f7e804213bf
  order.paymentStatus = null
  const calculateIsFullyPaid = order => {
    if (isNil(order.check)) {
      return null // not really false, we just didn't loaded the data
    }

    if (order.hasThirdPartyPayment) {
      order.paymentStatus = OrderPaymentStatus.PAID_THIRD_PARTY
      return true
    }

    if (order.check.outstandingBalance === 0) {
      order.paymentStatus = OrderPaymentStatus.PAID
      return true
    } else if (order.check.outstandingBalance < 0) {
      order.paymentStatus = OrderPaymentStatus.OVERPAYMENT
      order.isPaymentStatusOverPayment = true
      return true
    } else if (
      order.check.outstandingBalance > 0 &&
      order.check.outstandingBalance < order.totalPrice
    ) {
      order.paymentStatus = OrderPaymentStatus.UNDERPAYMENT
      return false
    } else if (order.check.outstandingBalance >= order.totalPrice) {
      // this should never be superior but we never know
      order.paymentStatus = OrderPaymentStatus.NOT_PAID
      return false
    }

    return false
  }
  order.isFullyPaid = calculateIsFullyPaid(order)

  order.paymentStatusLabel = getOrderPaymentStatusLabel(order.paymentStatus)
  order.paymentStatusType = getOrderPaymentStatusType(order.paymentStatus)

  return order
}

const formatAmountFromAccount = order => {
  if (!isNil(order.userAccountPayment)) {
    order.userAccountPayment.amountFromAccountFormatted = formatPriceWithCurrency(
      order.userAccountPayment.amountFromAccount,
      order.currency
    )
  }

  return order
}

const formatGrantAmount = order => {
  if (!isNil(order.admissionFees)) {
    order.admissionFees.amountIncludingTaxFormatted = formatPriceWithCurrency(
      order.admissionFees.amountIncludingTax,
      order.currency
    )
  }

  return order
}

const formatSource = order => {
  const source = order.source || {}

  order.hasSource = !isNil(order.source)

  source.isPlatformKiosk = source.platform === 'KIOSK'
  source.hasIdentifier = !isEmpty(source.identifier)

  order.source = source
  return order
}

const formatOrderUser = order => {
  order.isLinkedToPayMyTableUser = !isNil(order.userId)
  order.isLinkedToUser = !isNil(order.eater)

  if (order.eater) {
    order.eater.fullName = [order.eater.firstName, order.eater.lastName].filter(Boolean).join(' ')
  }

  return order
}

const formatAddress = order => {
  const address = order.address

  order.shouldHaveAddress = !order.isFromThirdParty
  order.hasAddress = !isNil(address)
  if (address) {
    address.gmapLink = getGoogleMapsUrl({
      latitude: address.geoPt.latitude,
      longitude: address.geoPt.longitude,
    })
  }

  return order
}

const formatData = order => {
  order.hasTableNumber = !isEmpty(order.tableNumber)
  // trick for "at bar": on site order with table number set to "bar"
  order.isOnBar = order.hasTableNumber && order.tableNumber.toLowerCase() === 'bar'

  order.hasFees = !isEmpty(order.fees)

  if (order.check) {
    order.check = formatCheck(order.check)
  }

  order.hasComment = !isEmpty(order.comment)
  order.hasAdmissionFees = !isNil(order.admissionFees) && !isEmpty(order.admissionFees.fees)

  return order
}

const formatItems = order => {
  order.menus = order.menus || []
  order.products = order.products || []
  order.items = order.menus.concat(order.products)

  return order
}

const formatOptionValue = (value, props = {}) => {
  value.hasAdditionalPrice = value.additionalPrice !== 0
  value.additionalPriceFormatted = formatPriceWithCurrency(value.additionalPrice, props.currency)
  return value
}

const formatOption = createFormatter(
  createSubObjectFormatter('values', createListFormatter(formatOptionValue))
)

const formatProduct = createFormatter(
  createSubObjectFormatter('options', createListFormatter(formatOption)),
  (product, props = {}) => {
    product.hasComment = !isEmpty(product.comment)

    if (props.fromMenu) {
      product.additionalPriceFormatted = formatPriceWithCurrency(
        product.additionalPrice,
        props.currency
      )
    } else {
      product.priceFormatted = formatPriceWithCurrency(product.price, props.currency)
    }
    return product
  }
)

const formatPart = createFormatter(
  createSubObjectFormatter(
    'products',
    createListFormatter((product, props = {}) =>
      formatProduct(product, { ...props, fromMenu: true })
    )
  )
)

const formatMenu = createFormatter(
  createSubObjectFormatter('parts', createListFormatter(formatPart)),
  (menu, props = {}) => {
    menu.priceFormatted = formatPriceWithCurrency(menu.price, props.currency)
    return menu
  }
)

const formatDeliveryAddress = data => {
  if (!isNil(data.address) && !isNil(data.address.id)) {
    data.address.formattedAddress = `${data.address.street}, ${data.address.postCode} ${
      data.address.city
    }, ${data.address.country}`
  }

  return data
}

// TODO: we should use an external payment format
const formatPayment = payment => {
  payment.amountFormatted = formatPriceWithCurrency(payment.amount, payment.currency)
  payment.totalWithTipFormatted = formatPriceWithCurrency(payment.amount + payment.tips)

  payment.pspTransactionUrl = formatPspTransactionUrl(payment.psp, payment.pspPaymentId)

  return payment
}

export const formatOrder = createFormatter(
  formatThirdParty,
  formatOrderNumber,
  formatDates,
  formatOrderMode,
  formatDeliveryAddress,
  formatStatus,
  formatOrderRestaurant,
  formatPrices,
  formatAmountFromAccount,
  formatGrantAmount,
  formatSource,
  formatOrderUser,
  formatAddress,
  formatData,
  createSubObjectFormatter('modifiers', formatModifiers),
  createSubObjectFormatter('payment', formatPayment),
  createSubObjectFormatter('fees', formatOrderFees),
  (order, props = {}) => {
    return createSubObjectFormatter('products', createListFormatter(formatProduct))(order, {
      ...props,
      currency: order.currency,
    })
  },
  (order, props = {}) => {
    return createSubObjectFormatter('menus', createListFormatter(formatMenu))(order, {
      ...props,
      currency: order.currency,
    })
  },
  createSubObjectFormatter('userAccountPayment', formatUserAccountPayment),
  createSubObjectFormatter('admissionFees', formatAdmissionFees),
  formatItems
)

export const formatOrders = createListFormatter(formatOrder)
