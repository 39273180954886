import Immutable from 'immutable'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import invariant from 'invariant'

import { DEFAULT_FORM_OPTIONS } from './constants'

let _forms = []
export const registerForms = forms => {
  _forms = forms
}

export const getForms = forms => _forms

export const getFormsDefaultState = () => {
  let defaultState = {}

  _forms.forEach(form => {
    if (process.env.__DEV__) {
      invariant(
        isNil(form.default) || !isFunction(form.getDefault),
        `form ${form.type} default must be a function named getDefault`
      )

      invariant(!isNil(form.type), 'form.type is required')
    }

    defaultState[form.type] = {
      formData: form.getDefault(),
      options: DEFAULT_FORM_OPTIONS,
    }
  })

  return Immutable.fromJS(defaultState)
}

/**
 * @param  {string} formType FormType of the form to retrive
 * @return {FormConfiguration} the configuration for the given FormType
 */
export const getForm = formType => {
  const form = find(_forms, form => formType === form.type)

  invariant(!isNil(form), 'form ' + formType + ' not registered')

  return form
}

export const getFormDefaultData = formType => {
  const form = getForm(formType)

  return form.getDefault()
}

export const getFormDefaultOptions = formType => {
  return { ...DEFAULT_FORM_OPTIONS }
}

export const getFormOptions = formType => getForm(formType).options

export const getFormCustomActions = formType => {
  const form = getForm(formType)

  const customActions = form.options.customActions
  invariant(
    isNil(customActions) || isObject(customActions),
    'form ' + formType + ' has no customActions'
  )

  return customActions || null
}

export const getFormReducer = formType => {
  const form = getForm(formType)

  const reducer = form.options.reducer
  invariant(isNil(reducer) || isFunction(reducer), 'form ' + formType + ' has no reducer')

  return reducer
}

export const getFormModifier = formType => {
  const form = getForm(formType)

  const modifier = form.options.modifier
  invariant(isNil(modifier) || isFunction(modifier), 'form ' + formType + ' has no modifier')

  return modifier
}

export const getValidator = formType => getForm(formType).validator
