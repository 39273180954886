import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import immutableTransform from 'redux-persist-transform-immutable'
import { connectRouter } from 'connected-react-router'

import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

import { uiReducer } from 'pmt-modules/reduxUi'
import uxReducer from './uxReducer'
import { formReducer } from '../../form'
import { searchReducer } from '../../search'
import { statisticsReducer } from '../../statistics'

import { i18nReducer } from 'pmt-modules/i18n'
import { getHistory } from 'pmt-modules/routing'

import { createExpirationTransform } from './reduxPersistTransformExpire'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: hardSet,
  transforms: [immutableTransform(), createExpirationTransform()],
}

const configureRootReducer = appOptions => {
  let reducers = {
    router: connectRouter(getHistory()),
    // entities are defined by the project with persistence set to false by default
    // we use whitelist to persist only reducers with the option set in [project]/src/config/entitites
    entities: persistReducer(
      {
        ...persistConfig,
        whitelist: Object.keys(appOptions.entities).filter(
          reducerName =>
            appOptions.entities[reducerName].options &&
            appOptions.entities[reducerName].options.persist
        ),
      },
      // It is an object, so we have to use combineReducers.
      combineReducers(appOptions.entities)
    ),

    ux: uxReducer,
    ui: uiReducer,
    form: formReducer,
    search: searchReducer,
    statistics: statisticsReducer,

    i18n: i18nReducer,
  }

  if (process.env.FEATURE_FIREBASE) {
    const firebaseReducer = require('pmt-modules/firebase').firebaseReducer
    reducers.firebase = firebaseReducer
  }

  // remove null reducers
  reducers = pickBy(reducers, identity)

  const rootReducer = combineReducers(reducers)

  return rootReducer
}

export default configureRootReducer
