//
// This module store global configuration and data for the order plugin,
// that don't requires their own module / reducer
//
import Immutable from 'immutable'

import { GetAppConfigAction } from './actions'

export * from './components'
export * from './actions'
export * from './events'
export * from './constants'
export * from './selectors'
export * from './config'
export * from './format'
export * from './middlewares'

//
// The appConfig is set as default by the front.
// It is given to our `Application` that call `setDefaultAppConfig`
//

const DEFAULT = Immutable.fromJS({
  data: null,
  isFetching: false,
  lastUpdated: null,
  error: null,
})

export const appConfigReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case GetAppConfigAction.REQUEST:
      return state.merge({
        preset: null,
        data: null,
        isFetching: true,
        lastUpdated: null,
        error: null,
      })

    case GetAppConfigAction.SUCCESS:
      // appConfig is get by fetching apiConsumer settings
      return state.merge({
        preset: action.response.preset,
        // set to empty object if null in order to know if fetch has successed
        data: action.response.settings || {},
        isFetching: false,
        lastUpdated: new Date(),
        error: null,
      })

    case GetAppConfigAction.FAILURE:
      return state.merge({
        preset: null,
        data: null,
        isFetching: false,
        lastUpdated: new Date(),
        error: action.error,
      })

    default:
      return state
  }
}
