//
// The dialog module is mostly inspired by Dan Abramov reponse on
// https://stackoverflow.com/questions/35623656/how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
//
// Note: We handle only one dialog at a time.
//
//
// ---
// Note about the Dialog component
//
// the dialog component can use
// - DialogRootProps: static data to override props of the `Dialog` in the `DialogRoot` file
// it can be used to change the value of `ignoreBackdropClick` for example
// - DialogStyles: static function used to pass the 'classes' to the Dialog. It behave like the
// `styles` function and `withStyles`
//
import Immutable, { fromJS } from 'immutable'
import isNil from 'lodash/isNil'
import remove from 'lodash/remove'

import {
  DialogAction,
} from './actions'

export * from './config'
export * from './actions'
export * from './constants'
export DialogRoot from './components/DialogRoot'
export * from './middlewares'
export * from './selectors'

/**
 */
const DEFAULT = {
  list: []
}

//
// dialog reducer.
//
export const dialog = (state = Immutable.fromJS(DEFAULT), action) => {
  let list = state.get('list')

  switch (action.type) {
    case DialogAction.SHOW_DIALOG:
      if (!isNil(list)) {
        list = list.toJS()

        list.push({
          type: action.dialogType,
          props: action.dialogProps,
        })
        return state.setIn(['list'], fromJS(list))
      }
      return state

    case DialogAction.HIDE_DIALOG:
      if (!isNil(list)) {
        list = list.toJS()
        remove(list, i => i.type === action.dialogType)
        return state.setIn(['list'], fromJS(list))
      }
      return state

    case DialogAction.RESET:
      return state.setIn(['list'], fromJS([]))

    default:
      return state
  }
}
