import { combineReducers } from 'redux'

import { theme } from '../../theme'

import { dialog } from '../../dialog'

const ux = combineReducers({
  theme,
  dialog,
})

export default ux
