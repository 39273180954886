import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getAuthProData = state => state.entities.authPro

export const getAuthProError = createSelector([getAuthProData], authProData => {
  const error = authProData.get('error')
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})

export const isFetchingAuthPro = createSelector(
  [getAuthProData],
  authProData => {
    return authProData.get('isFetching') || false
  }
)

export const haveAuthProCredentials = createSelector(
  [getAuthProData],
  authProData => {
    return authProData.get('auth') !== null
  }
)

export const isLoggedIn = createSelector([getAuthProData], authProData => {
  const auth = authProData.get('auth')

  return auth !== null
})

export const getBasicToken = createSelector([getAuthProData], authProData => {
  const authProState = authProData.get('auth')

  if (isNil(authProState)) {
    return null
  }

  return authProState
})
