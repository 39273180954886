import { createFormatter, createListFormatter, createSubObjectFormatter } from 'pmt-utils/format'
import { formatPriceWithCurrency } from 'pmt-utils/currency'

const formatAdmissionFee = admissionFee => {
  admissionFee.amountIncludingTaxFormatted = formatPriceWithCurrency(
    admissionFee.amountIncludingTax
  )

  return admissionFee
}

const formatAdmissionFeesData = admissionFees => {
  admissionFees.amountIncludingTaxFormatted = formatPriceWithCurrency(
    admissionFees.amountIncludingTax
  )

  return admissionFees
}

export const formatAdmissionFees = createFormatter(
  formatAdmissionFeesData,
  createSubObjectFormatter('fees', createListFormatter(formatAdmissionFee))
)
