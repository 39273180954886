import invariant from 'invariant'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import isString from 'lodash/isString'

import SdkRoutingEnum from './constants/SdkRoutingEnum'

let _routes = []

export const registerRoutes = routes => {
  _routes = routes
}

export const getRoutes = () => _routes

export const getConfigForRoute = route => {
  if (!route) {
    return null
  }

  if (isString(route)) {
    route = getRoute(route)
  }
  return find(_routes, routeDefinition => {
    return route.name === routeDefinition.definition.name
  })
}

//
//
//

let _history = null

export const registerHistory = history => {
  _history = history
}

export const getHistory = () => _history

//
//
//

// spread SdkRoutingEnum to allow dev pages from sdk
let _routingEnum = { ...SdkRoutingEnum }

export const registerRoutingEnum = routingEnum => {
  // Note: the given routingEnum must have spread SdkRoutingEnum
  _routingEnum = routingEnum
}

export const getRoutingEnum = () => _routingEnum

export const getRoute = routeName => {
  const route = getRoutingEnum()[routeName]
  invariant(
    !isNil(route),
    `Not route found for ${routeName}. Verify that you defined the route on the routes`
  )
  return route
}
