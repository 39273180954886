import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { OrderMode } from 'pmt-modules/order'
import { PagerCaptureModes } from 'pmt-modules/apiConsumer/constants'

import { createFormatter } from 'pmt-utils/format'

import { AppConfigPresets } from '../constants'

const formatOrderModes = appConfig => {
  // when using Digital Menu preset, order settings are null
  // so we use order modes but from digitalMenu settings
  if (isEmpty(appConfig.order) && !isEmpty(appConfig.digitalMenu?.modes)) {
    appConfig.order = {
      modes: appConfig.digitalMenu.modes,
    }
  }

  if (appConfig.order) {
    let formattedDatas = {
      hasOnSite: false,
      hasTakeAway: false,
      hasDelivery: false,
    }

    const modes = !isNil(appConfig.order.modes) ? appConfig.order.modes : []
    modes.forEach(mode => {
      switch (mode) {
        case OrderMode.ON_SITE:
          formattedDatas.hasOnSite = true
          break
        case OrderMode.TAKE_AWAY:
          formattedDatas.hasTakeAway = true
          break
        case OrderMode.DELIVERY:
          formattedDatas.hasDelivery = true
          break
        default:
          break
      }
    })

    appConfig.order.modesFormatted = { ...appConfig.order.modes, ...formattedDatas }
  }

  return appConfig
}

export const formatOrderAppConfig = createFormatter(order => {
  order.orderParamsForModes = {
    [OrderMode.ON_SITE]: order.onSite,
    [OrderMode.TAKE_AWAY]: order.takeAway,
    [OrderMode.DELIVERY]: order.delivery,
  }

  order.hasPagerCaptureNumericPad =
    order.pagerCaptureMode?.indexOf(PagerCaptureModes.NUMERIC_PAD) >= 0
  order.hasPagerCaptureScanner2D =
    order.pagerCaptureMode?.indexOf(PagerCaptureModes.SCANNER_2D) >= 0
  order.hasPagerCaptureContactless =
    order.pagerCaptureMode?.indexOf(PagerCaptureModes.CONTACTLESS) >= 0

  return order
})

export const formatAppConfig = createFormatter(formatOrderModes)

export const formatAppConfigPreset = createFormatter(data => {
  data.isPluginOrder = data.preset === AppConfigPresets.PLUGIN_ORDER
  data.isDigitalMenu = data.preset === AppConfigPresets.DIGITAL_MENU

  return data
})
