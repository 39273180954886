import React from 'react'
import PropTypes from 'prop-types'
import invariant from 'invariant'

import ClearIcon from 'pmt-ui/svg-icons/content/clear'
import TextField from 'pmt-ui/TextField'
import { withTheme } from 'pmt-ui/styles'

import TextFieldSuffixType from './TextFieldSuffixType'

class TextFieldCustom extends React.Component {
  state = {
    focus: false,
  }

  // Component styles.
  styles = {
    // Make sure the field label is on the right.
    label: {
      right: 0,
    },
    // Allow absolute positioning within the field's wrapper.
    // We need this to make the prefix show inline with the field.
    fieldWrapper: {
      position: 'relative',
      display: 'inline-block',
    },
    prefix: {
      position: 'absolute',
      right: -10,
      bottom: 0,
      lineHeight: '29px',
      fontSize: '15px',
      cursor: 'pointer',
      zIndex: 200,
    },
    prefixFocus: {
      color: this.props.theme.palette.primary.main,
    },
  }

  // Handle field change event, pass name and value to the onChange callback.
  handleChange = (event, index) => {
    if (this.props.onChange !== null) {
      // Run callback with the name and value.
      this.props.onChange(this.props.name, event.target.value)
    }
  }

  toggleFocus(focus) {
    this.setState({
      focus,
    })
  }

  handleFocus = () => {
    this.toggleFocus(true)
  }

  handleBlur = () => {
    this.toggleFocus(false)
  }

  componentWillMount() {
    if (this.props.fullWidth) {
      this.styles.fieldWrapper.display = 'block'
    }
  }

  getSuffixForType(suffixType) {
    switch (suffixType) {
      case TextFieldSuffixType.CLEAR:
        return (
          <span>
            <ClearIcon />
          </span>
        )

      default:
        invariant(false, `invalid suffixType ${suffixType}`)
    }
  }

  // Render component.
  render() {
    // Optional symbol prefix, example use: currency or percentage.
    // TODO: prefix is a suffix, use suffix.
    let prefix = this.props.prefix || this.props.suffix || ''
    const prefixStyles = this.state.focus
      ? { ...this.styles.prefix, ...this.styles.prefixFocus }
      : this.styles.prefix

    if (this.props.suffixType) {
      prefix = this.getSuffixForType(this.props.suffixType)
    }

    return (
      <div className={this.props.className}>
        <label style={this.styles.fieldWrapper}>
          <TextField
            type="text"
            onClick={this.props.onClick}
            label={this.props.label}
            value={this.props.value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            name={this.props.name}
            fullWidth={this.props.fullWidth}
            disabled={this.props.disabled || false}
            helperText={this.props.helperText}
          />

          {prefix ? (
            <span
              style={prefixStyles}
              onClick={event => {
                if (this.props.onSuffixClick) {
                  event.preventDefault()
                  event.stopPropagation()
                  this.props.onSuffixClick(event)
                }
              }}
            >
              {prefix}
            </span>
          ) : null}
        </label>
      </div>
    )
  }
}

TextFieldCustom.defaultProps = {
  onChange: null,

  suffixType: null,
}

TextFieldCustom.propTypes = {
  /**
   * @deprecated
   * Use suffix
   */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Event called when clicking on the text field
   * @type {[type]}
   */
  onClick: PropTypes.func,

  onSuffixClick: PropTypes.func,

  /**
   * Some generic suffix can be used directly by specifing this suffixType
   */
  suffixType: PropTypes.oneOf([TextFieldSuffixType.CLEAR]),
}

// hoc delete static, so we set our static on the hoc result
const TextFieldCustomWithHOC = withTheme(TextFieldCustom)

TextFieldCustomWithHOC.SuffixType = TextFieldSuffixType

export default TextFieldCustomWithHOC
