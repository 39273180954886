import { getQueryParam } from 'pmt-utils/url'

import {
  APP_TOKEN_QUERY_PARAM,
  API_INVALID_GEOLOCATION_ERROR_CODE,
  API_INVALID_APP_TOKEN_ERROR_CODE,
  API_INVALID_ON_SITE_SECURITY,
} from './constants'

export const isAppSecurityError = error =>
  error &&
  (error.code === API_INVALID_GEOLOCATION_ERROR_CODE ||
    error.code === API_INVALID_APP_TOKEN_ERROR_CODE ||
    error.code === API_INVALID_ON_SITE_SECURITY || 
    error.errorCode === "MAINTENANCE_MODE" || 
    error.errorCode === "TOO_MANY_REQUESTS")

export const getAppTokenCodeOnUrl = () => getQueryParam(APP_TOKEN_QUERY_PARAM)
