import Application from 'pmt-modules/archi/Application'

import devMiddlewares from './config/devMiddlewares'
import middlewares from './config/middlewares'
import entities from './config/entities'
import environment from './config/environment'
import forms from './config/forms'
import dialogs from './config/dialogs'
import getApiManagerOptions from './config/getApiManagerOptions'
import theme from './config/theme'
import analytics from './config/analytics'
import i18n from './config/i18n'
import routingEnum from './config/routingEnum'

const appOptions = {
  analytics,
  devMiddlewares,
  entities,
  environment,
  forms,
  middlewares,
  i18n,
  dialogs,
  getApiManagerOptions,
  routingEnum,
  theme,
}

Application(appOptions)
