import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { withStyles } from 'pmt-ui/styles'
import Button from 'pmt-ui/Button'

const styles = theme => ({
  root: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cancelBtn: {
    marginRight: 10,
  },
  submitBtn: {},
})

const FormButtons = ({ classes, submit = {}, cancel = {}, onSubmit, onReset }) => (
  <div className={classes.root}>
    <Button
      variant="contained"
      color="default"
      className={classes.cancelBtn}
      disabled={cancel.disabled || false}
      onClick={onReset}
    >
      {tr('global.cancel')}
    </Button>
    <Button
      variant="contained"
      color="primary"
      className={classes.submitBtn}
      disabled={submit.disabled || false}
      onClick={onSubmit}
    >
      {tr('global.save')}
    </Button>
  </div>
)

export default withStyles(styles)(FormButtons)
