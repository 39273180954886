import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  redirectTo,
  replaceWith,
  reloadTo,
  goBack,
  openExternalLink,
  redirectToExternal,
  updatePageQueries,
} from '../../actions'

import { getRoute, getRoutingEnum } from '../../config'

/**
 * Gives the shortcut to routing module such as:
 * - actions
 */
class RoutingContainer extends React.Component {
  render() {
    const {
      redirectTo,
      replaceWith,
      reloadTo,
      goBack,
      openExternalLink,
      redirectToExternal,
      children,
    } = this.props

    return children({
      redirectTo,
      replaceWith,
      goBack,
      reloadTo,
      openExternalLink,
      redirectToExternal,
      updatePageQueries,
      getRoute,
      getRoutingEnum,
    })
  }
}

RoutingContainer.propTypes = {
  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
  // no state to map yet
  return {}
}

export default connect(
  mapStateToProps,
  {
    redirectTo,
    replaceWith,
    reloadTo,
    goBack,
    openExternalLink,
    redirectToExternal,
    // not an action, so we do not have to map the updatePageQueries as an action
    // updatePageQueries,
  }
)(RoutingContainer)
