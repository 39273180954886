import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isEqual from 'lodash/isEqual'

import { updateStatisticsOptions } from '../actions'
import { makeGetStatisticsOptions, makeGetStatisticsConfig } from '../selectors'
import { statisticsOptionsAreEqual } from '../utils'

/**
 * @specs N/A
 *
 * A HOC that handles the options for a statistics.
 * It retains the options modifications on its state.
 * We update them on the store only when the given function `onApplyOptions` is called.
 *
 * Requirements:
 * - groupType defined in ../StatisticsGroupType
 */
class StatisticsOptionsModifierContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: { ...props.options },
      optionsChanged: false,
      optionsAreDefault: this.areOptionsDefault(props, props.options),
    }
  }

  componentDidUpdate(prevProps) {
    if (!statisticsOptionsAreEqual(this.props.config, this.props.options, prevProps.options)) {
      this.setState({
        options: this.props.options,
        optionsChanged: false,
      })
    }
  }

  areOptionsDefault(props, options) {
    return isEqual(props.config.defaultOptions, options)
  }

  handleChangeOptions = options => {
    this.setState({
      options,
      optionsChanged: true,
      optionsAreDefault: this.areOptionsDefault(this.props, options),
    })
  }

  handleApplyOptions = () => {
    this.props.updateStatisticsOptions(this.props.groupType, this.state.options)
  }

  handleResetOptions = () => {
    this.setState(
      {
        options: {
          ...this.props.config.defaultOptions,
        },
        optionsChanged: true,
        optionsAreDefault: true,
      },
      () => {
        // directly apply options and reload data
        this.handleApplyOptions()
      }
    )
  }

  render() {
    const { children, config } = this.props

    const { options, optionsChanged, optionsAreDefault } = this.state

    if (!options || !config) {
      // wait for default options to be processed
      return null
    }

    return children({
      options,
      config,
      optionsChanged,
      optionsAreDefault,
      onChangeOptions: this.handleChangeOptions,
      onApplyOptions: this.handleApplyOptions,
      onResetOptions: this.handleResetOptions,
    })
  }
}

StatisticsOptionsModifierContainer.defaultProps = {}

StatisticsOptionsModifierContainer.propTypes = {
  children: PropTypes.func.isRequired,

  groupType: PropTypes.string.isRequired,
}

const mapStateToProps = (state, props) => {
  const getOptions = makeGetStatisticsOptions()
  const getConfig = makeGetStatisticsConfig()

  return {
    options: getOptions(state, props),
    config: getConfig(state, props),
  }
}

export default connect(mapStateToProps, {
  updateStatisticsOptions,
})(StatisticsOptionsModifierContainer)
