import React from 'react'
import { connect } from 'react-redux'

import isEmpty from 'lodash/isEmpty'

import { ThemeProvider } from 'pmt-ui/styles'
import CssThemeProvider from 'pmt-modules/theme/CssThemeProvider'
import { getCurrentTheme } from 'pmt-modules/theme'

import { DialogRoot } from 'pmt-modules/dialog'

import { isFetchingI18nPhrases, getI18nPhrases } from 'pmt-modules/i18n'

import AppConfigContainer from '../appConfig/components/AppConfigContainer'

/**
 * The content of the app will have the `router` as children.
 * We englobe the router withe the theme providers in order to not re-render those providers when
 * the route change, but only when the currentTheme change.
 *
 * if locale is changed, any render modification will have to be implemented on each project view
 */
class Content extends React.PureComponent {
  render() {
    const { currentTheme, isFetchingI18nPhrases, i18nPhrases, children } = this.props
    // TODO:

    const content = (
      <ThemeProvider theme={currentTheme}>
        <div className="u-sizeFullHeight">
          <CssThemeProvider override />

          <AppConfigContainer isFetchingI18nPhrases={isFetchingI18nPhrases && isEmpty(i18nPhrases)}>
            {// it happens that the children need data to be rendered. This data is not available while the
            // loading is made.
            // But even if we don't display the children because we are showing the loading, the children
            // is build
            // Passing the children as a function avoid to build it when we don't need id

            () => children}
          </AppConfigContainer>

          <DialogRoot />
        </div>
      </ThemeProvider>
    )

    if (process.env.FEATURE_BO) {
      const DragAndDropContainer = require('pmt-ui/DragAndDrop/DragAndDropContainer').default
      return <DragAndDropContainer>{content}</DragAndDropContainer>
    }

    return content
  }
}

const mapStateToProps = (state, ownProps) => ({
  currentTheme: getCurrentTheme(state),
  isFetchingI18nPhrases: isFetchingI18nPhrases(state),
  i18nPhrases: getI18nPhrases(state),
})

export default connect(
  mapStateToProps,
  {}
)(Content)
