import isEmpty from 'lodash/isEmpty'
import startsWith from 'lodash/startsWith'

import { getDefaultLocale, getCurrentLanguageWithoutRegionCode } from './utils.js'
import { findOnArray } from 'pmt-utils/array'

// TODO: handle region code
// better handling?
export const getForCurrentLocale = locales => {
  if (!locales) {
    return ''
  }

  // try to find for current language
  const currentLanguage = getCurrentLanguageWithoutRegionCode().toUpperCase()
  let locale = findOnArray(
    locales,
    locale =>
      locale.locale && startsWith(locale.locale.toLowerCase(), currentLanguage.toLowerCase())
  )

  // try to find for default language
  if (!locale) {
    const defaultLanguage = getDefaultLocale()
      .substr(0, 2)
      .toUpperCase()

    if (defaultLanguage !== currentLanguage) {
      locale = findOnArray(
        locales,
        locale =>
          locale.locale && startsWith(locale.locale.toLowerCase(), defaultLanguage.toLowerCase())
      )
    }
  }

  // none found, fallback to a default
  if (!locale && !isEmpty(locales)) {
    locale = locales[0]
  }

  // avoid crash
  return locale ? locale.text : ''
}

export const getForLocale = (locales, localeParam) => {
  let locale = findOnArray(
    locales,
    locale => locale && startsWith(locale.locale.toLowerCase(), localeParam.toLowerCase())
  )

  // none found, fallback to a default
  if (!locale && !isEmpty(locales)) {
    locale = locales[0]
  }

  // avoid crash
  return locale ? locale.text : ''
}
