import React from 'react'

import { withStyles } from 'pmt-ui/styles'

import Popover from 'pmt-ui/Popover'

const styles = theme => ({
  header: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  popoverRoot: {
    // maxHeight: '90vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
})

/**
 * Display the ListChooser as a popover.
 * It is displayed as a dialog on desktop screens.
 * It can be forced using the `ListChooser.type` prop with `ListChooserType.POPOVER` value
 */

const PopoverView = ({ anchorEl, open, header, content, classes, onClose }) => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    className={classes.popoverRoot}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
  >
    {header && <div className={classes.header}>{header}</div>}

    {content}
  </Popover>
)

export default withStyles(styles)(PopoverView)
