const transformFeedbackAnswerForApi = feedbackAnswer => {
  const { answerScore, answers, answerComment } = feedbackAnswer

  const apiFeedbackAnswer = {
    answerScore: {
      score: answerScore.score,
    },
    answerComment: {
      comment: answerComment.comment,
    },
    answers: answers.map(transformAnswer),
  }

  return apiFeedbackAnswer
}

const transformAnswer = answer => {
  const asked = answer.shouldBeDisplayed
  const apiAnswer = {
    asked,
    type: answer.type,
  }

  if (asked) {
    if (answer.isTypeScore) {
      if (answer.answerScore) {
        apiAnswer.answerScore = {
          score: answer.answerScore.score,
        }
      }
    } else if (answer.isTypeFreeText) {
      if (answer.answerFreeText) {
        apiAnswer.answerFreeText = {
          text: answer.answerFreeText.text,
          feedbackType: answer.answerFreeText.feedbackType,
        }
      }
    } else if (answer.isTypeCriterias) {
      if (answer.answerCriterias) {
        apiAnswer.answerCriterias = {
          comment: answer.answerCriterias.comment,
          criterias: answer.answerCriterias.criterias
            .filter(criteria => criteria.isSelected)
            .map(criteria => ({
              feedbackType: criteria.feedbackType,
              criteria: criteria.criteria,
            })),
        }
      }
    }
  }

  return apiAnswer
}

export default transformFeedbackAnswerForApi
