export template from './template'

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// This function is used to insert interpreted spaces in string
// In some cases, spaces are not interpreted so you need this function
// to work this out
export const replaceSpaceWithUnbreakableUnicodeSpace = (string) => {
  return string.replace(/ /g, '\u00a0')
}
