import find from 'lodash/find'
import { countryCodes } from './countryCodes'

export * from './countryCodes'

export const getCountryNameForCode = (countryCode) => {
  let country = find(countryCodes, (country) => country.code === countryCode)

  if (!country) {
    return null
  }

  return country.name
}
