import React from 'react'

import clsx from 'clsx'
import { withStyles } from '../styles'
import Button from '@material-ui/core/Button'

const styles = theme => ({
  buttonFlat: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderRadius: 'none',
    boxShadow: 'none',
    textTransform: 'initial',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonFlatRaised: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    background: theme.palette.primary.main,
    border: 'none',
    borderRadius: 'none',
    boxShadow: 'none',
    textTransform: 'initial',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  buttonFlatInactive: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    background: theme.pmt.colors.white,
    color: theme.pmt.colors.grey500,
    border: `1px solid ${theme.pmt.colors.grey500}`,
    borderRadius: 'none',
    boxShadow: 'none',
    textTransform: 'initial',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      background: theme.pmt.colors.white,
    },
  },
  buttonFlatWarning: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    background: theme.pmt.colors.red800,
    color: theme.pmt.colors.white,
    border: 'none',
    borderRadius: 'none',
    boxShadow: 'none',
    textTransform: 'initial',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '&:hover': {
      background: theme.pmt.colors.red900,
    },
  },
})

class ButtonFlat extends React.PureComponent {
  render() {
    const {
      warning,
      inactive,
      // raised will be replaced by contained.
      raised,
      contained,
      className,
      classes,
      children,
      ...otherProps
    } = this.props

    const classNames = warning
      ? clsx(className, classes.buttonFlatWarning)
      : inactive
        ? clsx(className, classes.buttonFlatInactive)
        : raised || contained
          ? clsx(className, classes.buttonFlatRaised)
          : clsx(className, classes.buttonFlat)

    return (
      <Button
        className={classNames}
        variant={raised || contained ? 'contained' : 'text'}
        {...otherProps}
      >
        {children}
      </Button>
    )
  }
}

export default withStyles(styles)(ButtonFlat)
