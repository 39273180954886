import { tr } from 'pmt-modules/i18n'
export const EPaymentMode = {
  AMERICAN_EXPRESS: 1,
  VISA: 2,
  MASTERCARD: 4,
  CASH: 8,
  RESTAURANT_TICKET: 16,
}

export const isAcceptingCreditCardType = (creditCardType, payMode) =>
  (creditCardType & payMode) !== 0

export const getPaymentLabel = paymentMode => {
  const PaymentLabel = {
    1: tr('global.payment.label.american_express'),
    2: tr('global.payment.label.visa'),
    4: tr('global.payment.label.mastercard'),
    8: tr('global.payment.label.cash'),
    16: tr('global.payment.label.restaurant_ticket'),
  }
  return PaymentLabel[paymentMode]
}

// export const CreditCardType = {
//   AMEX: 'American Express',
//   VISA: 'Visa',
//   CB: 'Carte Bleue',
//   MASTERCARD: 'Mastercard',
//   MAESTRO: 'Maestro',
// }

export const PspRegisterUserCardType = {
  WEB_SERVICES: 'WEB_SERVICES',
  WEB_REDIRECT: 'WEB_REDIRECT',
}

export const InvalidCardResponseCode = '13100'
