//
// Note: this file should never be import directly. Use a getter via getters.js
//

import merge from 'lodash/merge'

const Env = {
  COMMON: {
    Logger: {
      SENTRY_URL: 'https://2b3c16aa948d4c05a1a61c03c7563fd7@sentry.io/1441345',
      DISPLAY_REPORT_DIALOG: true,
    },

    SHOULD_USE_APP_CONFIG: false,

    IS_LOCAL: false,
    IS_DEV: false,
    IS_PREPROD: false,
    IS_PROD: false,
  },

  DEV: {
    ENV: 'DEV',

    HOSTNAME: 'web.dev.paymytable.com',
    API_URL: 'https://paymytabledev.appspot.com/rest',
    API_CONSUMER: 'NTcyMzI3NjU5Njc0MDA5NjpPQzdxR2NmM2Vqc1g',

    IS_DEV: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'web.preprod.paymytable.com',
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    API_CONSUMER: 'NTY1MTUyMTYwMTUzNjAwMDpPQzdxR2NmM2Vqc1g=',

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'web.paymytable.com',
    API_URL: 'https://api.paymytable.com/rest',
    API_CONSUMER: 'NTY1MTI2NzcxMjk3NDg0ODpPQzdxR2NmM2Vqc1g=',

    IS_PROD: true,
  },
}

let currentEnv = Env.DEV
const hostname = window.location.hostname

if (hostname === Env.DEV.HOSTNAME || hostname === 'localhost') {
  currentEnv = Env.DEV
} else if (hostname === Env.PREPROD.HOSTNAME) {
  currentEnv = Env.PREPROD
} else if (hostname === Env.PRODUCTION.HOSTNAME) {
  currentEnv = Env.PRODUCTION
}

if (process.env.__DEV__) {
  // currentEnv = Env.PRODUCTION
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
