import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'
import invariant from 'invariant'
import isFunction from 'lodash/isFunction'

/**
 * Generate a simple selector with basic isFetching / getData / getErrors
 * @param  {function} getState  Function to retrieve entity from state
 *                              state => state.entities.ENTITY_NAME
 * @param  {function|object} formatData  Callback function to format data or object { formatter: , props:}
 * @return {object}             Object with 3 selectors
 *                              isFetching / getData / getErrors
 */
const createPaginateSelector = (getState, formatData = null) => {
  return {
    isFetching: createSelector(
      [getState],
      dataState => (isNil(dataState) ? false : dataState.get('isFetching') || false)
    ),

    getList: createSelector([getState], state => {
      if (isNil(state)) {
        return null
      }

      const data = state.get('data')

      if (isNil(data)) {
        return null
      }

      if (formatData !== null) {
        if (typeof formatData === 'function') {
          return formatData(data.toJS())
        } else {
          invariant(
            isFunction(formatData.formatter),
            `formatData object must contains the formatter`
          )
          return formatData.formatter(data.toJS(), formatData.props)
        }
      }
      return data.toJS()
    }),

    getPaging: createSelector([getState], state => {
      if (isNil(state)) {
        return null
      }

      const paging = state.get('paging')

      if (isNil(paging)) {
        return null
      }

      return paging.toJS()
    }),

    getError: createSelector([getState], dataState => {
      if (isNil(dataState)) {
        return null
      }

      const error = dataState.get('error')

      if (isNil(error)) {
        return null
      }

      return error.toJS()
    }),
  }
}

export default createPaginateSelector
