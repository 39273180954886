import { createApiCallAction, createApiEnumAction } from 'pmt-modules/api/utils'
import { createAction } from 'pmt-modules/redux'

import FeedbackApi from 'pmt-modules/api/calls/FeedbackApi'
import transformFeedbackAnswerForApi from './transformFeedbackAnswerForApi'

export const FetchFeedbackAnswer = createApiEnumAction('FEEDBACK::ANSWER::GET')

export const fetchFeedbackAnswer = (feedbackAnswerToken, temporaryScore) =>
  createApiCallAction(
    FetchFeedbackAnswer,
    FeedbackApi.getFeedbackAnswer(feedbackAnswerToken, temporaryScore),
    {
      feedbackAnswerToken,
      temporaryScore,
    }
  )

//
//
//

export const PutFeedbackAnswer = createApiEnumAction('FEEDBACK::ANSWER::PUT')

export const putFeedbackAnswer = feedbackAnswer => {
  const apiFeedbackAnswer = transformFeedbackAnswerForApi(feedbackAnswer)
  return createApiCallAction(
    PutFeedbackAnswer,
    FeedbackApi.putFeedbackAnswer(feedbackAnswer.token, apiFeedbackAnswer),
    {
      feedbackAnswer,
      apiFeedbackAnswer,
    }
  )
}

//
//
//

export const UpdateFeedbackAnswerFormAction = 'FEEDBACK::ANSWER::UPDATE_FORM'

export const updateFeedbackAnswerForm = feedbackAnswer =>
  createAction(UpdateFeedbackAnswerFormAction, { feedbackAnswer })

//
//
//

export const GetFeedbackAnswerUserAction = createApiEnumAction('FEEDBACK::ANSWER::USER::GET')

export const getFeedbackAnswerUser = (userId, limit = 10, cursor = '') =>
  createApiCallAction(
    GetFeedbackAnswerUserAction,
    FeedbackApi.getFeedbackAnswerUser(userId, limit, cursor),
    {
      userId,
      limit,
      cursor,
    }
  )

//
//
//

export const GetFeedbackAnswersAction = createApiEnumAction('FEEDBACK::ANSWERS::GET')

export const getFeedbackAnswers = (feedbackCampaignId, limit = 10, cursor = '') =>
  createApiCallAction(
    GetFeedbackAnswersAction,
    FeedbackApi.getFeedbackAnswers(feedbackCampaignId, limit, cursor),
    {
      feedbackCampaignId,
      limit,
      cursor,
    }
  )
