import isNil from 'lodash/isNil'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import { createSelector } from 'reselect'

import { filterDuplicates, existsOnArray, findOnArray } from 'pmt-utils/array'

import { formatRestaurant, formatRestaurantsMap, formatRestaurantsListData } from './format'

export const getRestaurantState = state => (isNil(state) ? null : state.entities.restaurant)

const getRestaurantIdOnProps = (state, props) => (isNil(props) ? null : props.restaurantId)

export const getRestaurantListData = createSelector([getRestaurantState], restaurantState => {
  // retrieve restaurants from list data
  const restaurantsListData = restaurantState.getIn(['list', 'data'])
  return isNil(restaurantsListData) ? null : formatRestaurantsListData(restaurantsListData.toJS())
})

/**
 * Gives only the restaurants list loaded, updated with the restaurant's data loaded separatly.
 * We do not add restaurants that are not on the list, we just update the data.
 */
export const getRestaurantList = createSelector(
  [getRestaurantListData, getRestaurantState],
  (restaurantListData, restaurantState) => {
    // retrieve restaurants from list data
    let restaurantList = restaurantListData?.restaurants || []
    // retrieve restaurants directly stored in state
    let restaurants = restaurantState.toJS()
    restaurants = map(restaurants, r => r.restaurant)
      // filter list/post from state
      .filter(Boolean)
      // keep only the restaurants that are on our list
      .filter(r => existsOnArray(restaurantList, r))

    // filter duplicates
    return filterDuplicates(
      [...(restaurantList || []), ...restaurants],
      r => r.id,
      (restaurant, other) => other.modificationDate > restaurant.modificationDate
    )
  }
)

/**
 * Gives all the restaurants list loaded merged with the restaurants loaded seperatly
 */
export const getAllRestaurants = createSelector(
  [getRestaurantListData, getRestaurantState],
  (restaurantListData, restaurantState) => {
    // retrieve restaurants from list data
    let restaurantList = restaurantListData?.restaurants || []

    // retrieve restaurants directly stored in state
    let restaurants = restaurantState.toJS()
    restaurants = map(restaurants, r => r.restaurant)
      // filter list/post from state
      .filter(Boolean)

    // filter duplicates
    return filterDuplicates(
      [...(restaurantList || []), ...restaurants],
      r => r.id,
      (restaurant, other) => other.modificationDate > restaurant.modificationDate
    )
  }
)

export const makeIsFetchingRestaurant = () => {
  return createSelector(
    [getRestaurantIdOnProps, getRestaurantState],
    (restaurantId, restaurants) => {
      if (isNil(restaurants)) {
        return null
      }
      const restaurantData = restaurants.get(restaurantId)
      if (isNil(restaurantData)) {
        return false
      }

      return restaurantData.get('isFetching')
    }
  )
}

export const makeGetRestaurantData = () => {
  return createSelector(
    [getRestaurantIdOnProps, getRestaurantState],
    (restaurantId, restaurants) => {
      if (isNil(restaurants)) {
        return null
      }
      const restaurantData = restaurants.get(restaurantId)
      if (isNil(restaurantData)) {
        return null
      }
      return restaurantData.toJS()
    }
  )
}

export const makeGetRestaurantError = () => {
  return createSelector(
    [getRestaurantIdOnProps, getRestaurantState],
    (restaurantId, restaurants) => {
      if (isNil(restaurants)) {
        return null
      }

      const restaurantData = restaurants.get(restaurantId)
      if (isNil(restaurantData)) {
        return null
      }

      const restaurantError = restaurantData.get('error')
      if (isNil(restaurantError)) {
        return null
      }

      return restaurantError.toJS()
    }
  )
}

export const makeGetRestaurant = () => {
  return createSelector(
    [getRestaurantIdOnProps, getRestaurantState],
    (restaurantId, restaurants) => {
      if (isNil(restaurants)) {
        return null
      }
      const restaurantData = restaurants.get(restaurantId)
      if (isNil(restaurantData)) {
        return null
      }
      const restaurant = restaurantData.get('restaurant')

      if (isNil(restaurant)) {
        return null
      }
      return formatRestaurant(restaurant.toJS())
    }
  )
}

export const makeGetRestaurantFromRestaurantList = () => {
  return createSelector(
    [getRestaurantIdOnProps, getRestaurantState],
    (restaurantId, restaurants) => {
      const restaurantEntity = restaurants.toJS()
      const restaurantList = restaurantEntity?.list?.data?.restaurants || []

      const restaurantFromList = findOnArray(
        restaurantList,
        restaurant => restaurant.id === restaurantId
      )
      return restaurantFromList
    }
  )
}

export const getLoadedRestaurantsIds = createSelector([getRestaurantState], restaurantsData => {
  return restaurantsData.keySeq().toArray()
})

export const getRestaurantMap = createSelector([getRestaurantState], restaurantsData => {
  const restaurantsDataMap = restaurantsData.toJS()

  const restaurantsMap = {}
  forEach(restaurantsDataMap, (restaurantData, key) => {
    restaurantsMap[key] = restaurantData.restaurant
  })

  return formatRestaurantsMap(restaurantsMap)
})

export const getRestaurantPost = createSelector([getRestaurantState], restaurantData => {
  if (isNil(restaurantData)) {
    return null
  }

  const restaurantPost = restaurantData.getIn(['post', 'data'], null)
  if (!isNil(restaurantPost)) {
    return restaurantPost.toJS()
  }

  return null
})

export const isFetchingRestaurantPost = createSelector([getRestaurantState], restaurantData => {
  if (isNil(restaurantData)) {
    return false
  }

  return restaurantData.getIn(['post', 'isFetching'], false)
})

export const getRestaurantPostError = createSelector([getRestaurantState], restaurantData => {
  const error = restaurantData.getIn(['post', 'error'])
  if (isNil(error)) {
    return null
  }

  return error.toJS()
})

export const isFetchingRestaurantList = createSelector([getRestaurantState], restaurantData => {
  if (isNil(restaurantData)) {
    return false
  }

  return restaurantData.getIn(['list', 'isFetching'], false)
})

export const getRestaurantListError = createSelector([getRestaurantState], restaurantData => {
  const error = restaurantData.getIn(['list', 'error'])
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})
