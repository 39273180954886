export default {
  // for key we use the `ENV` from our config
  DEV: {
    waitForAppConfig: false,
    identifiers: [
        // UA (old)
      /*{
        trackingId: 'UA-105853425-14',
        debug: true,
        gaOptions: {
          name: 'pmt_tracker_dev',
          cookieDomain: 'auto',
        },
      },
       */
        // GA4
      {
        trackingId: 'G-25QF9EJXXL',
        debug: true,
        gaOptions: {
          name: 'pmt_tracker_dev',
          cookieDomain: 'auto',
        },
      },
    ],
    /*
      options.nonce	string Optional Used for Content Security Policy (CSP) more
      options.testMode	boolean Default false
      options.gaOptions	object Optional Reference
      options.gtagOptions	object Optional
      options.legacyDimensionMetric	boolean Default true

      */
    options: {
      gtagOptions : { 'debug_mode':true }
    }
  },
  PREPROD: {
    waitForAppConfig: false,
    identifiers: [
        // UA (old)
      /*{
        trackingId: 'UA-105853425-15',
        gaOptions: {
          name: 'pmt_tracker_preprod',
          cookieDomain: 'auto',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-EQ24KFB453',
        gaOptions: {
          name: 'pmt_tracker_preprod',
          cookieDomain: 'auto',
        },
      },
    ],
  },
  PROD: {
    waitForAppConfig: false,
    identifiers: [
        // UA (old)
        /*
      {
        trackingId: 'UA-105853425-16',
        gaOptions: {
          name: 'pmt_tracker_prod',
          cookieDomain: 'auto',
        },
      },
         */
        // GA4
      {
        trackingId: 'G-S9H77KN2YB',
        gaOptions: {
          name: 'pmt_tracker_prod',
          cookieDomain: 'auto',
        },
      },
    ],
  },
}
