import { createSimpleReducer, createPaginateReducer } from '../../redux'

import { FetchFeedbackCampaign, FetchFeedbackCampaignList, ExportFeedbackCampaign } from './actions'
import { combineReducers } from 'redux'

const fetchFeedbackCampaignReducer = createSimpleReducer(FetchFeedbackCampaign)
const fetchFeedbackCampaignsListReducer = createPaginateReducer(FetchFeedbackCampaignList)
const exportFeedbackCampaignReducer = createSimpleReducer(ExportFeedbackCampaign)

export const feedbackCampaignReducer = combineReducers({
  fetch: fetchFeedbackCampaignReducer,
  list: fetchFeedbackCampaignsListReducer,
  export: exportFeedbackCampaignReducer,
})
