import { createTransform } from 'redux-persist'
import each from 'lodash/each'

import { getEntities } from 'pmt-modules/reducers'

const PERSIST_EXPIRE_DEFAULT_KEY = 'persistExpiresAt'

export const createExpirationTransform = config => {
  config = config || {}
  config.expireKey = config.expireKey || PERSIST_EXPIRE_DEFAULT_KEY
  config.defaultState = config.defaultState || {}

  const dateToUnix = date => +(date.getTime() / 1000).toFixed(0)

  const inbound = (state, key) => {
    const entity = getEntities()[key]

    if (entity && entity.options && entity.options[config.expireKey]) {
      const jsonState = JSON.parse(state)
      jsonState.data = {
        ...jsonState.data,
        [config.expireKey]: entity.options[config.expireKey],
      }
      state = JSON.stringify(jsonState)
    }

    return state
  }

  const outbound = (state, key) => {
    if (!state) return state

    each(JSON.parse(state), value => {
      if (!value || typeof value !== 'object') {
        return
      }

      if (!value.hasOwnProperty(config.expireKey)) {
        return
      }

      var expireDate = value[config.expireKey]

      if (!expireDate) {
        return
      }

      if (dateToUnix(new Date(expireDate)) < dateToUnix(new Date())) {
        this.update(config.defaultState)
      }
    })

    return state
  }

  return createTransform(inbound, outbound)
}

export default createExpirationTransform
