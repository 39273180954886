import React from 'react'
import { createLoadable } from 'pmt-utils/react-loadable'

import isNil from 'lodash/isNil'
import invariant from 'invariant'

import { DISABLE_FORM_DEV_DATA_QUERY_PARAM } from 'pmt-modules/form'

import RoutingEnum from './routingEnum'
import PageLoading from '../components/PageLoading'

const createAsyncPage = loader => createLoadable(loader, () => <PageLoading />)

// list of the queries that are globaGl to all our urls, and should be kept
const globalQueries = ['apiVersion', DISABLE_FORM_DEV_DATA_QUERY_PARAM]

// merge our globalQueries with the specific queries of the route
const getQueries = (queries = []) => [...globalQueries, ...queries]

/**
 * We define  the `handler` for each route of our RoutingEnum.
 * The `handler` is the React Component to display for the route.
 * It should be a container in most cases.
 */
const routes = [
  {
    definition: RoutingEnum.GLOBAL_FEEDBACK_ANSWER,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "FeedbackAnswerPage" */ '../pages/feedback/answer')
    ),
    props: {},
  },

  {
    definition: RoutingEnum.GLOBAL_FEEDBACK_CONFIRM,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "FeedbackConfirmPage" */ '../pages/feedback/confirm/FeedbackConfirmPage')
    ),
    props: {},
  },

  //
  // 404
  //

  {
    definition: RoutingEnum.PAGE_NOT_FOUND,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PageNotFound" */ '../pages/pageNotFound')
    ),
    props: {},
  },
]

export default routes.map(route => {
  // handle props.query to set global queries
  if (!route.props.query) {
    route.props.query = getQueries([])
  }

  invariant(!isNil(route.handler), `${route.definition.name}: handler is required`)
  invariant(!isNil(route.definition), `${route}: definition is required`)

  return route
})
